import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { MoreMenuItem } from '../../../../types/MoreMenu.types';

interface IProps {
  menuItem: MoreMenuItem;
  selected?: boolean;
  selectItem: Function;
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    cursor: 'pointer',
    width: 200,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px 5px 0px 0px',
    borderBottom: '1px solid #BFDDEB',
    position: 'relative',
  },
  selected: {
    color: palette.text.primary,
    backgroundColor: palette.common.white,
    boxShadow: '2px -5px 15px rgba(71, 104, 122, 0.15)',
    borderBottom: 'none',

    '&:after': {
      backgroundColor: '#fff',
      height: 10,
      content: '""',
      position: 'absolute',
      bottom: -5,
      left: 0,
      right: 0,
    },
  },
  selectedLabel: {
    fontWeight: 700,
  },
  label: {
    margin: `${spacing(3)}px 0`,
  },
}));

const MenuItem = ({ menuItem, selected = false, selectItem }: IProps) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.root, {
        [classes.selected]: selected,
      })}
      onClick={() => selectItem(menuItem)}
    >
      <Typography
        className={clsx(classes.label, {
          [classes.selectedLabel]: selected,
        })}
      >
        {menuItem.title}
      </Typography>
    </Box>
  );
};

export default MenuItem;
