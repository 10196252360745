import { useEffect, useState, useContext } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Switch, Route, Redirect } from 'react-router-dom';

import { menuItems } from '../../const/menu';
import Header from '../Header';
import Menu from '../Menu';
import More from '../../pages/More';
import { UserContext } from '../../App';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: 'flex',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  menu: {
    backgroundColor: palette.background.default,
    paddingTop: spacing(14),
    textAlign: 'center',
  },
  content: {
    flexGrow: 1,
    padding: spacing(3),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  headerWrapper: {
    paddingBottom: spacing(3),
  },
  contentWrapper: {
    height: '92%',
    overflow: 'visible',
  },
}));

const Layout = () => {
  const classes = useStyles();
  const [menus, setMenus] = useState(menuItems);
  const user = useContext(UserContext);

  useEffect(() => {
    if (user.corporation === 'ALL') {
      setMenus(menuItems.filter((m) => !m.nonAdminOnly));
    } else {
      setMenus(menuItems.filter((m) => !m.adminOnly));
    }
  }, [user]);

  return (
    <Box className={classes.root}>
      <Box className={classes.menu}>
        <Menu />
      </Box>
      <Box className={classes.content}>
        <Box className={classes.headerWrapper}>
          <Header />
        </Box>
        <Box className={classes.contentWrapper}>
          <Switch>
            <Route path="/more" key="/more">
              <More />
            </Route>
            {menus.map((menu) => (
              <Route exact path={menu.link} key={menu.link}>
                {menu.page}
              </Route>
            ))}
            <Redirect to="/" />
          </Switch>
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
