import {
  Table as MaterialTable,
  TableBody,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { ISort, ITableColumn, ITableRow } from '../../types/Table.types';
import { fetchData, sortBy } from './api';
import TableCell from './TableCell';
import TableHeaderCell from './TableHeaderCell';
import noTestsImage from '../../icons/emptyPlaceholder.png';
import Loader from '../Loader';
import { UserContext } from '../../App';

const useStyles = makeStyles(({ palette }) => ({
  header: {
	borderTop: `1px solid ${palette.primary.main}`,
	borderBottom: `1px solid ${palette.primary.main}`,
  },
  headerCell: {
	color: palette.primary.main,
  },
  cell: {
	border: 'none',
  },
  row: {
	'&:hover': {
	  backgroundColor: palette.background.default,
	},
  },
  selectedRow: {
	backgroundColor: palette.background.default,
  },
  clickableRow: {
	cursor: 'pointer',
  },
  body: {
	overflow: 'auto',
  },
  noResultsCont: {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column',
	marginTop: 200,
  },
  image: {
	height: 400,
	width: 500,
	left: 500,
  },
  noResults: {
	color: '#BDBDBD',
	fontSize: 20,
	lineHeight: '28px',
	marginTop: 15,
  },
  editBox: {
	padding: 16,
  },
  editText: {
	color: palette.primary.main,
	fontWeight: 400,
  },
}));

interface IProps {
  columns: ITableColumn[];
  link: string;
  isRowSelected?: (row: ITableRow) => boolean;
  onRowClick?: (row: ITableRow) => void;
  dataGetter?: (rows: any[]) => void;
  hasEditRow?: boolean;
  editAction?: (rows: any) => void;
  filterData?: (rows: any) => any;
  update?: boolean | null;
  EditAction?: (rows: any) => void;
  tableData: any;
  setTableData: any;
  sortData?: ISort | undefined;
  getAllTests?: boolean;
}

const Table = ({
  columns,
  link,
  onRowClick,
  isRowSelected,
  hasEditRow,
  dataGetter,
  editAction,
  filterData,
  update,
  EditAction,
  tableData,
  sortData,
  setTableData,
  getAllTests,
}: IProps) => {
  const classes = useStyles();
  
  const [loading, setLoading] = useState<boolean>(false);
  const [sort, setSort] = useState<ISort | undefined>({
	name: link === "corporations" ? "created_at" : "submitted_at",
	order: 'desc',
  });
  
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);
  
  const user = useContext(UserContext);

  useEffect(() => {
	
	if (getAllTests) {
	  const fetchRows = async () => {
		setLoading(true);
		const companies = await fetchData('corporations');
		if (companies.length) {
		  let tests = [];
		  companies.forEach(async (comp, index) => {
			const fetchedRows = await fetchData(
			  `corporations/${comp.id}/tests`,
			  sort
			);
			tests = [
			  ...tests,
			  ...fetchedRows.map((el) => {
				return {
				  ...el,
				  corporate_id: comp.id,
				  employee_id: comp.employee_id,
				};
			  }),
			];
			const sortedTests = tests.sort(sortBy(sort.name, sort.order));
			if (index === companies.length - 1) {
			  setTableData(sortedTests);
			  setLoading(false);
			}
		  });
		}
	  };
	  fetchRows();
	} else {
	  const fetchRows = async () => {
		setLoading(true);
		const fetchedRows = await fetchData(link, sort);
		setTableData(fetchedRows.map((row) => {
			return {
				...row,
				corporate_id: user.corporation,
				employee_id: user.corporateId
			}
		}));
		setLoading(false);
	  };
	  fetchRows();
	}
  }, [link, sort, filterData, setTableData, update, getAllTests, user]);
  
  const onChangeRowsPerPage = (e) => {
	setPageSize(e.target.value);
  };
  
  const onPageChange = (e, page) => {
	setPage(page);
  };
  
  const editActionClick = (row: any) => {
	EditAction(row);
  };
  
  return (
	<React.Fragment>
	  {loading ? (
		<Loader />
	  ) : (
		<>
		  {tableData.length ? (
			<MaterialTable className={classes.body}>
			  <TableHead>
				<TableRow className={classes.header}>
				  {columns.map((column) => (
					<TableHeaderCell
					  sort={sort}
					  key={column.title}
					  className={clsx(classes.cell, classes.headerCell)}
					  column={column}
					  onSort={setSort}
					/>
				  ))}
				</TableRow>
			  </TableHead>
			  <TableBody>
				{tableData
				.slice(page * pageSize, page * pageSize + pageSize)
				.map((row, index) => (
				  <TableRow
					key={index}
					className={clsx(classes.row, {
					  [classes.clickableRow]: onRowClick !== undefined,
					  [classes.selectedRow]:
					  isRowSelected && isRowSelected(row),
					})}
					onClick={() => onRowClick && onRowClick(row)}
				  >
					{columns.map((column) => (
					  <TableCell
						key={`${index}-${column.field}`}
						row={row}
						column={column}
						className={classes.cell}
					  />
					))}
					{hasEditRow && (
					  <Box
						onClick={(e) => {
						  e.stopPropagation();
						  editActionClick(row);
						}}
						className={classes.editBox}
					  >
						<Typography className={classes.editText}>
						  View
						</Typography>
					  </Box>
					)}
				  </TableRow>
				))}
			  </TableBody>
			  <TablePagination
				count={tableData.length}
				page={page}
				rowsPerPageOptions={[20, 50, 100]}
				onPageChange={onPageChange}
				rowsPerPage={pageSize}
				onRowsPerPageChange={onChangeRowsPerPage}
			  />
			</MaterialTable>
		  ) : (
			<div className={classes.noResultsCont}>
			  <img src={noTestsImage} alt="img" className={classes.image} />
			  <div className={classes.noResults}>No results yet</div>
			</div>
		  )}
		</>
	  )}
	</React.Fragment>
  );
};

export default Table;
