import { makeStyles } from '@material-ui/core/styles';
import {useContext, useState} from 'react';
import { Button, TextField, Box, Typography } from '@material-ui/core';
import {GroupContext} from '../Groups/Groups';

const useStyles = makeStyles(({ spacing, palette }) => ({
  topInput: {
    marginTop: 10,
    marginBottom: spacing(1),
    backgroundColor: 'white',
	border: 'none',
	borderRadius: 10,
	paddingLeft: 10
  },
  button: {
    height: 48,
    backgroundColor: palette.primary.main,
    color: 'white',
    fontSize: 16,
    lineHeight: '24px',
    width: '100%',
    marginRight: 5,
	borderRadius: 10
  },
  bg: {
    bottom: 0,
    right: 0,
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(180, 191, 193, 0.6)',
  },
  paper: {
    width: '100%',
    backgroundColor: palette.background.default,
    height: 270,
    borderRadius: 5,
    display: 'flex',
    alignSelf: 'end',
    bottom: 0,
    right: 0,
    position: 'absolute',
  },
  header: {
    height: 95,
    borderBottom: '1px solid #616161',
    position: 'relative',
  },
  groupForm: {
    padding: '20px 24px',
  },
  buttonsContainer: {
    position: 'absolute',
    bottom: 20,
    right: 30,
    left: 30,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fieldLable: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: 20,
  },
  fieldLableSecond: {
    color: palette.text.primary,
    paddingTop: 12,
    fontSize: 16,
  },
  buttonClear: {
    height: 48,
    backgroundColor: 'white',
    color: palette.primary.main,
    fontSize: 16,
    lineHeight: '24px',
    width: '100%',
    border: '1px solid #64A2C8',
    marginLeft: 5,
	borderRadius: 10
  },
}));

interface IProps {
  onClose: () => void;
  firebaseCall: () => void;
  actionText: string;
}

const UpdateGroupPopup = ({
  onClose,
  firebaseCall,
  actionText
}: IProps) => {
  const classes = useStyles();
  const {setUpdateCompanies, updateCompanies} = useContext(GroupContext);
  const [textFieldError, setTextFieldError] = useState('');
  const [txt, setTxt] = useState('');

  const callApi = async () => {
    setTextFieldError('');
    await firebaseCall();
    onClose();
    setUpdateCompanies(!updateCompanies);
  };

  return (
    <div className={classes.bg}>
      <div className={classes.paper}>
        <Box className={classes.groupForm}>
          <Typography className={classes.fieldLable}>
            Are you sure you want to {actionText.toLowerCase()} this group?
          </Typography>
          <Typography className={classes.fieldLableSecond}>
            Type {actionText} here
          </Typography>
          <TextField
            fullWidth
            placeholder={actionText}
            type="name"
            className={classes.topInput}
            value={txt}
            onChange={(e) => setTxt(e.target.value)}
            error={textFieldError.length > 0}
            helperText={textFieldError}
			      InputProps={{ disableUnderline: true }}
          />
        </Box>
        <Box className={classes.buttonsContainer}>
          <Button
            fullWidth
            variant="contained"
            className={classes.button}
            size="large"
            onClick={callApi}
            disabled={txt !== actionText}
          >
            Confirm
          </Button>
          <Button
            fullWidth
            variant="contained"
            className={classes.buttonClear}
            size="large"
            onClick={onClose}
          >
            Cancel
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default UpdateGroupPopup;
