import moment, { unitOfTime } from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from '../const';
import { IFirebaseDate } from '../types';
import { ITest } from '../types/Test.types';

export const getTestResult = (test: ITest): string => {

  if(test.discarded) return 'Discarded';

  if (!test.valid) {
    return 'Invalid';
  }

  return test.positive ? 'Positive' : 'Negative';
};

export const dateToMoment = (date: IFirebaseDate) => moment.unix(date.seconds).local();

export const formatDate = (date: IFirebaseDate) =>
  date ? dateToMoment(date).format(DATE_FORMAT) : '-';

export const formatDateTime = (date: IFirebaseDate) =>
  date ? dateToMoment(date).format(`${DATE_FORMAT}, ${TIME_FORMAT}`) : '-';

export const getTestTimeDiff = (
  startTime: IFirebaseDate | null,
  endTime: IFirebaseDate | null,
  unitOfTime: unitOfTime.Diff = 'minute'
) =>
  startTime && endTime
    ? dateToMoment(endTime).diff(dateToMoment(startTime), unitOfTime).toString()
    : '-';
