import {
  Card,
  CardHeader,
  CardContent,
  Box,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { ITest, ISymptom } from '../../types/Test.types';
import { Close } from '@material-ui/icons';
import { formatDateTime, getTestResult, getTestTimeDiff } from '../../utils';
import PhotoPreviewPopup from '../../components/PhotoPreviewPopup';
import closeIcon from '../../icons/close_blue.png';

import { fetchTest } from './api';

interface IProps {
  link: string;
  onClose: () => void
}

interface IDataSetProps {
  label: string;
  text: string;
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    backgroundColor: palette.background.default,
    width: 351,
    minWidth: 351,
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    position: 'relative',
  },
  basicDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(1),
    marginBottom: spacing(3),
  },
  advancedDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
    marginBottom: spacing(2),
  },
  image: {
    height: "auto",
    width: 100,
    borderRadius: 4,
    objectFit: "cover",
    cursor: "pointer"
  },
  qrDetails: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: spacing(2),
  },
  detailsBox: {
    display: 'flex',
  },
  child: {
    flex: 'auto',
    maxWidth: '60%',
  },
  detailsChild: {
    textAlign: 'right',
  },
  medicalProfessionalDetailsButton: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  medicalProfessionalDetailsBackground: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    zIndex: 1,
  },
  medicalProfessionalDetails: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: spacing(2),
    backgroundColor: palette.background.default,
    zIndex: 2,
  },
  medicalProfessionalCloseIcon: {
    cursor: 'pointer',
  },
  qrDetailsLink: {
    whiteSpace: 'normal',
    overflowWrap: 'anywhere',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  closeImg: {
    width: 20,
    height: 20,
    cursor: "pointer"
  },
}));

export const DataSet = ({ label, text }: IDataSetProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.detailsBox}>
      <Box className={classes.child}>
        <Typography color="primary">{label}</Typography>
      </Box>
      <Box className={clsx(classes.detailsChild, classes.child)}>
        <Typography>{text}</Typography>
      </Box>
    </Box>
  );
};

const TestDetails = ({ link, onClose }: IProps) => {
  const classes = useStyles();
  const [test, setTest] = useState<ITest>();
  const [photoPreviewVisible, setPhotoPreviewVisible] =
    useState<boolean>(false);
  const [photoPreviewUrl, setPhotoPreviewUrl] = useState<string>('');
  const [medicalProfessionalOpen, setMedicalProfessionalOpen] =
    useState<boolean>(false);

  const openImagePreview = (url) => {
    setPhotoPreviewUrl(url);
    setPhotoPreviewVisible(true);
  };

  useEffect(() => {
    fetchTest(link).then(setTest);
  }, [link]);

  if (!test) {
    return null;
  }

  const testPerformer =
    test.medical_professional === null
      ? `${test.patient.first_name} ${
          test.patient.last_name
        }, User, ${formatDateTime(test.submitted_at)}`
      : `${test.medical_professional.first_name} ${
          test.medical_professional.last_name
        }, Medical Professional, ${formatDateTime(test.submitted_at)}`;

  const symptoms = (symptoms: ISymptom[]) => {
    const filteredSymptoms = symptoms?.sort((a, b) => {
        if(a.symptom === "Other") return 1
        else return a.symptom < b.symptom ? -1 : 1 
      })
      .filter(s => (s.severity && s.severity !== 0) || s.description)
      .map(s => `${s.symptom}, ${s.severity ?? s.description}\n`)

    return (
      <Box className={classes.detailsBox}>
        <Box className={classes.child}>
          <Typography color="primary">Symptoms</Typography>
        </Box>
        {
          filteredSymptoms &&
          <Box className={clsx(classes.detailsChild, classes.child)}>
            {
              filteredSymptoms.map(s => <Typography>{s}</Typography>)
            }
          </Box>
        }
      </Box>
      )
  }

  return (
    <Card className={classes.root}>
      <Box className={classes.basicDetails}>
        <Typography variant="h4" style={{ display: "flex", justifyContent: "space-between" }}>
          <b>
            {test.patient.first_name} {test.patient.last_name}
          </b>
          <img className={classes.closeImg} alt="" src={closeIcon} onClick={onClose}/>
        </Typography>
        <Typography>Email: {test.patient.email}</Typography>
        {test.patient.contact_number && (
          <Typography>Contact: {test.patient.contact_number}</Typography>
        )}
        {test.patient.job_title && (
          <Typography>Dept / Job Title: {test.patient.job_title}</Typography>
        )}
        {test.patient.age && <Typography>Age: {test.patient.age}</Typography>}
      </Box>
      <Box className={classes.advancedDetails}>
        <DataSet label="Test Results" text={getTestResult(test)} />
        {
          symptoms(test.symptoms)
        }
        <DataSet label="Test Type" text={test.type} />
        <DataSet label="Test Brand" text={test.brand} />
        <DataSet label="Test Date" text={formatDateTime(test.submitted_at)} />
        <DataSet
          label="Submitted by"
          text={
            test.medical_professional?.job_title
              ? 'Medical Professional'
              : 'User'
          }
        />
      </Box>
      <Box className={classes.advancedDetails}>
        <Box className={classes.detailsBox}>
          <Box className={classes.child}>
            <Typography color="primary">Image</Typography>
          </Box>
          <Box className={clsx(classes.detailsChild, classes.child)}>
            {
              !!test.photo && <img src={test.photo} className={classes.image} onClick={() => openImagePreview(test.photo)} alt=""/>
            }
          </Box>
        </Box>
      </Box>
      <Box className={classes.advancedDetails}>
        <DataSet
          label="Total Time"
          text={`${getTestTimeDiff(test.started_at, test.submitted_at)} min`}
        />
        {test.photo_taken_at && (
          <DataSet
            label="Photo Taken At"
            text={`${getTestTimeDiff(
              test.started_at,
              test.photo_taken_at
            )} min`}
          />
        )}
      </Box>
      {!!test.scanned_qr && (
        <Box className={classes.qrDetails}>
          <Typography color="primary">QR URL</Typography>
          <Typography className={classes.qrDetailsLink}>
            {test.scanned_qr}
          </Typography>
        </Box>
      )}
      <Box>
        <Typography variant="body2" color="textSecondary">
          {testPerformer}
        </Typography>
        {test.medical_professional && (
          <Typography
            className={classes.medicalProfessionalDetailsButton}
            color="primary"
            onClick={() => setMedicalProfessionalOpen(true)}
          >
            See details
          </Typography>
        )}
        {medicalProfessionalOpen && (
          <>
            <Box className={classes.medicalProfessionalDetailsBackground} />
            <Card className={classes.medicalProfessionalDetails} elevation={1}>
              <CardHeader
                title={
                  <Typography variant="h4">
                    {test.medical_professional.first_name}{' '}
                    {test.medical_professional.last_name}
                  </Typography>
                }
                action={
                  <Close
                    fontSize="large"
                    className={classes.medicalProfessionalCloseIcon}
                    onClick={() => setMedicalProfessionalOpen(false)}
                  />
                }
              />
              <CardContent>
                {test.medical_professional.age && (
                  <Typography gutterBottom>
                    Age: {test.medical_professional.age}
                  </Typography>
                )}
                <Typography gutterBottom>
                  Email: {test.medical_professional.email}
                </Typography>
                {test.medical_professional.job_title && (
                  <Typography gutterBottom>
					          Dept / Job Title: {test.medical_professional.job_title}
                  </Typography>
                )}
                {test.medical_professional.contact_number && (
                  <Typography gutterBottom>
                    Contact: {test.medical_professional.contact_number}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </>
        )}
      </Box>
      {photoPreviewVisible && (
        <PhotoPreviewPopup
          photoPreviewVisible={photoPreviewVisible}
          photoPreviewUrl={photoPreviewUrl}
          onClose={() => setPhotoPreviewVisible(false)}
        />
      )}
    </Card>
  );
};

export default TestDetails;
