import React, { useContext } from 'react';
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import blueIcon from '../../icons/blue-icon.png';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../App';
import { IFilter } from '../../types/Filter.types';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  statsCard: {
    borderRadius: 20,
    backgroundColor: palette.background.default,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    maxHeight: 170,
    paddingRight: 5,
  },
  title: {
    [breakpoints.down('md')]: {
      fontSize: '1.3rem',
    },
  },
  red: {
    color: '#B71C1C',
  },
  green: {
    color: '#4CAF50',
  },
  orange: {
    color: '#F57C00',
  },
  usersPercent: {
    fontWeight: 500,
  },
  contentCard: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  },
  arrowIcon: {
    left: '40%',
    position: 'relative',
    height: 0,
    width: 0,
    top: 21,
  },
  paddingStyle: {
    paddingTop: 20,
  },
}));

export interface Stats {
  testedPositive: number;
  testedNegative: number;
  testedInvalid: number;
  allTests?: number;
  userId?: string;
  medicalId?: string;
}

interface IStatsProps {
  stats: Stats;
  filters: IFilter | any;
}

const StatsCards: React.FC<IStatsProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const user = useContext(UserContext);

  const { stats, filters } = props;

  const onArrowClick = (filter: string) => {
    let navigationFilter = {
      startDate: filters.startDate,
      positive: false,
      negative: false,
      invalid: false,
      antigen: true,
      pcr: true,
      endDate: filters.endDate,
    };
    switch (filter.toLowerCase()) {
      case 'positive':
        navigationFilter.positive = true;
        break;
      case 'negative':
        navigationFilter.negative = true;
        break;
      case 'invalid':
        navigationFilter.invalid = true;
        break;
    }
    history.push({
      pathname: '/users',
      state: {
        navigationFilter: navigationFilter,
      },
    });
  };

  const testsSum =
    stats.testedPositive + stats.testedNegative + stats.testedInvalid;

  const statsData = [
    {
      name: 'Positive',
      color: 'red',
      usersNumber: stats.testedPositive ? stats.testedPositive : 0,
      percent: stats.testedPositive
        ? ((stats.testedPositive / testsSum) * 100).toFixed(2)
        : 0,
    },
    {
      name: 'Negative',
      color: 'green',
      usersNumber: stats.testedNegative ? stats.testedNegative : 0,
      percent: stats.testedNegative
        ? ((stats.testedNegative / testsSum) * 100).toFixed(2)
        : 0,
    },
    {
      name: 'Invalid',
      color: 'orange',
      usersNumber: stats.testedInvalid ? stats.testedInvalid : 0,
      percent: stats.testedInvalid
        ? ((stats.testedInvalid / testsSum) * 100).toFixed(2)
        : 0,
    },
  ];

  return (
    <React.Fragment>
      {statsData.map((item, index) => (
        <Grid item container xs key={index}>
          <Card className={classes.statsCard}>
            {user.corporation !== 'ALL' && (
              <IconButton
                onClick={() => onArrowClick(item.name)}
                className={classes.arrowIcon}
                aria-label="settings"
              >
                <img src={blueIcon} alt="icon arrow" />
              </IconButton>
            )}
            <CardContent
              className={`${classes.contentCard} ${
                user.corporation === 'ALL' ? classes.paddingStyle : ''
              } `}
            >
              <Grid xs={12} container direction="column" spacing={2}>
                <Typography
                  className={`${classes[item.color]} ${classes.title}`}
                  gutterBottom
                  variant="h5"
                  align="center"
                >
                  <b>{item.name}</b>
                </Typography>
                <Typography
                  className={classes.usersPercent}
                  align="center"
                  variant="h4"
                  gutterBottom
                >
                  {item.usersNumber}
                </Typography>
                <Typography align="center" variant="subtitle1">
                  {item.percent}%
                </Typography>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </React.Fragment>
  );
};

export default StatsCards;
