import React, { useContext, useState, useCallback } from 'react';
import { Box, Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Table } from '../../components/Table';
import { ITableRow } from '../../types/Table.types';
import TestDetails from '../Tests/TestDetails';
import { UserContext } from '../../App';
import { ITest } from '../../types/Test.types';
import {
  exportTestsFields,
  getTestExportRow,
  testsColumns,
} from '../Tests/const';
import TestsHeader from '../Tests/TestsHeader';
import { getTestsListLink, getTestItemLink } from '../../api/const';
import backIcon from '../../icons/backIcon.png';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    height: 'calc(100% - 122px)',
    display: 'flex',
    flexDirection: 'row',
    margin: '0 30px',
    width: 'calc(100% - 160px)',
  },
  table: {
    overflow: 'auto',
    width: '100%',
    height: 'calc(100% - 80px)',
  },
  header: {
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  headerTxt: {
    fontSize: 48,
    lineHeight: ' 130%',
    fontWeight: 'bold',
    color: palette.text.primary,
    marginLeft: 20,
  },
  backImg: {
    width: 40,
    height: 40,
    cursor: 'pointer',
  },
  paper: {
    position: 'absolute',
    width: '100%',
    backgroundColor: 'white',
    height: '100%',
    top: 90,
    right: 0,
    left: 125,
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  leftColumn: {
    height: '100%',
    width: '100%',
    marginRight: 20,
  },
}));

interface IProps {
  isOpen: boolean;
  onClose?: () => void;
  link: string;
  selectedUserId: string;
}

const UserTestsHistory = ({
  isOpen,
  onClose,
  selectedUserId,
}: IProps) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const user = useContext(UserContext);

  const [tableData, setTableData] = useState<ITest[]>([]);
  const [selectedTestId, setSelectedTestId] = useState<string | null>(null);

  const onRowClick = (row: ITableRow) =>
    isRowSelected(row) ? setSelectedTestId(null) : setSelectedTestId(row.id);
  const isRowSelected = (row: ITableRow) => row.id === selectedTestId;

  function getModalStyle() {
    const right = 0;
    return {
      right: `${right}%`,
    };
  }

  const setData = useCallback(
    (arr: any) => {
      let filteredArr = [];
      let patientEmail = arr.find((el: any) => el.id === selectedUserId)
        .patient.email;

      filteredArr = arr.filter(
        (item: ITest) => item.patient.email === patientEmail
      );

      setTableData(filteredArr);
    },
    [selectedUserId]
  );

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isOpen}
      onClose={onClose}
      BackdropProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <div style={modalStyle} className={classes.paper}>
        <Box className={classes.root}>
          <Box className={classes.leftColumn}>
            <Box className={classes.header}>
              <img
                src={backIcon}
                alt="back"
                className={classes.backImg}
                onClick={onClose}
              />
              <Typography className={classes.headerTxt}>
                History of Results
              </Typography>
            </Box>

            <Card className={classes.table}>
              {tableData.length > 0 && (
                <TestsHeader
                  tests={tableData}
                  getTestExportRow={getTestExportRow}
                  exportTestsFields={exportTestsFields}
                />
              )}

              <Table
                columns={testsColumns}
                link={getTestsListLink(user.corporation)}
                onRowClick={onRowClick}
                isRowSelected={isRowSelected}
                tableData={tableData}
                setTableData={setData}
              />
            </Card>
          </Box>
          {selectedTestId && (
            <TestDetails
              link={getTestItemLink(user.corporation, selectedTestId)}
              onClose={() => setSelectedTestId(null)}
            />
          )}
        </Box>
      </div>
    </Modal>
  );
};

export default UserTestsHistory;
