import { IMenuItem } from '../types/Menu.types';

import testResultsIcon from '../icons/testResultsIcon.png';
import groupIcon from '../icons/group.png';
import dashboardIcon from '../icons/dashboard.png';
import dashboardSelectedIcon from '../icons/dashboardSelected.png';
import userScreenIcon from '../icons/userScreenIcon.png';
import testScreenIcon from '../icons/testScreenIcon.png';
import groupScreenIcon from '../icons/groupScreenIcon.png';
import Tests from '../pages/Tests';
import Groups from '../pages/Groups';
import UsersIcon from '../icons/user.png';
import Users from '../pages/Users';
import Dashboard from '../pages/Dashboard';

export const menuItems: IMenuItem[] = [
  {
    link: '/',
    title: 'Dashboard',
    icon: dashboardIcon,
    selectedIcon: dashboardSelectedIcon,
    page: <Dashboard />,
    adminOnly: false,
    nonAdminOnly: false
  },
  {
    link: '/tests',
    title: 'Test Results',
    icon: testScreenIcon,
    selectedIcon: testResultsIcon,
    page: <Tests />,
    adminOnly: false,
    nonAdminOnly: false
  },
  {
    link: '/users',
    title: 'Users',
    icon: userScreenIcon,
    selectedIcon: UsersIcon,
    page: <Users />,
    adminOnly: false,
    nonAdminOnly: true
  },
  {
    link: '/groups',
    title: 'Groups',
    icon: groupScreenIcon,
    selectedIcon: groupIcon,
    page: <Groups />,
    adminOnly: true,
    nonAdminOnly: false
  },
];
