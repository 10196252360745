import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { moreMenuItems } from '../../const/moreMenu';
import MenuItem from './components/MenuItem';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    textAlign: 'center',
    margin: '0 auto',
    width: '100%',
    height: 65,
    display: 'flex',
    padding: `${spacing(3)}px ${spacing(0.5)}px 0`,
  },
  content: {
    padding: spacing(5),
    overflow: 'auto',
    display: 'flex',
    flexGrow: 1,
  },
}));

const More = () => {
  const classes = useStyles();
  const [currentSection, setCurrentSection] = useState(moreMenuItems[0]);
  return (
    <>
      <Box className={classes.root}>
        {moreMenuItems.map((menuItem) => (
          <MenuItem
            key={menuItem.key}
            menuItem={menuItem}
            selectItem={setCurrentSection}
            selected={menuItem.section === currentSection.section}
          />
        ))}
      </Box>
      <Box className={classes.content}>{currentSection.content}</Box>
    </>
  );
};

export default More;
