// USERS
export const getUserLink = (id: string) => `users/${id}`;
export const getGroupLink = (id: string) => `corporations/${id}`;

// TESTS
export const getTestsListLink = (groupId: string) =>
  `corporations/${groupId}/tests`;

export const getGroupListLink = () => `corporations`;

export const getTestItemLink = (groupId: string, id: string) =>
  `${getTestsListLink(groupId)}/${id}`;

export const getGroupItemLink = (groupId: string) => `corporations/${groupId}`;

export const getReportsPdfLink = (corporate_id: string, id: string) =>
  `generateTestReport?corporate_id=${corporate_id}`;
