import { MouseEvent } from 'react';
import { Box, Menu, Typography, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExportIcon from '../../icons/exportColored.png';

const useStyles = makeStyles(({ spacing, palette }) => ({
  menuBox: {
    justifyContent: 'left',
  },
  img: {
    marginRight: 8,
  },
  menu: {
    marginTop: 4,
  },
}));

interface IProps {
  anchorEl: Element;
  handleClick: (event: MouseEvent<HTMLSpanElement>) => void;
  handleClose: () => void;
  chooseExportoption: (opt: string) => void;
}

const ExportResultsDropdown = ({
  anchorEl,
  handleClick,
  handleClose,
  chooseExportoption,
}: IProps) => {
  const open = Boolean(anchorEl);

  const classes = useStyles();

  return (
    <Box>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={classes.menu}
      >
        <Box>
          <MenuItem
            onClick={() => chooseExportoption('csv')}
            key={'csv'}
            className={classes.menuBox}
          >
            <img src={ExportIcon} alt="" className={classes.img} />
            <Typography>CSV File</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => chooseExportoption('pdf')}
            key={'pdf'}
            className={classes.menuBox}
          >
            <img src={ExportIcon} alt="" className={classes.img} />
            <Typography>PDF with photos</Typography>
          </MenuItem>
        </Box>
      </Menu>
    </Box>
  );
};

export default ExportResultsDropdown;
