import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import React from 'react';
import closeIcon from '../../icons/close_blue.png';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(({ spacing, palette }) => ({
  paper: {
    position: 'absolute',
    height: 550,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 10,
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  closeImg: {
    width: 20,
    height: 20,
  },
  imageDetails: {
    height: 550,
    borderRadius: 10,
  },
  image: {
    height: 550,
    borderRadius: 10,
  },
  closeIconBox: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    height: 40,
    width: 40,
    backgroundColor: '#fff',
    position: 'absolute',
    borderRadius: 28,
    top: -18,
    right: -18,
    cursor: 'pointer',
  },
}));

interface IProps {
  onClose: () => void;
  photoPreviewVisible: boolean;
  photoPreviewUrl: string;
}

const PhotoPreviewPopup = ({
  onClose,
  photoPreviewVisible,
  photoPreviewUrl,
}: IProps) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={photoPreviewVisible}
      onClose={onClose}
    >
      <Box className={classes.paper}>
        {!!photoPreviewUrl && (
          <Box className={classes.imageDetails}>
            <img src={photoPreviewUrl} className={classes.image} alt=""/>
            <Box className={classes.closeIconBox} onClick={onClose}>
              <img className={classes.closeImg} alt="" src={closeIcon} />
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default PhotoPreviewPopup;
