import { doc, getDoc } from 'firebase/firestore';
import { ITest } from '../../types/Test.types';
import { firestore } from '../../utils/firebase';

export const fetchTest = async (link: string): Promise<ITest | null> => {
  const docSnap = await getDoc(doc(firestore, link));

  if (docSnap.exists()) {
    return docSnap.data() as ITest;
  }

  return null;
};
