import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Loader = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <CircularProgress size={50} />
    </Box>
  );
};

export default Loader;
