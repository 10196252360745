import { Card, Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';

import { changeGroupStatus, deleteGroup, downloadTestKits, fetchGroup } from './api';
import { IGroup } from '../../types/Group.types';
import { DataSet } from '../Tests/TestDetails';
import { fetchData } from '../../components/Table/api';
import { getTestsListLink } from '../../api/const';
import {
  calculateNumberOfUsers,
  calculateTestedType,
} from '../Dashboard/helpers';
import UpdateGroupPopup from '../Group/UpdateGroupPopup';
import closeIcon from '../../icons/close_blue.png';
import { useJsonToCsv } from 'react-json-csv';
import { firebaseConfig } from '../../const/firebase.config';

interface IProps {
  link: string;
  selectedGroupId: string;
  onClose: () => void
}

interface Stats {
  tests: number;
  users: number;
  testedPositive: number;
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    backgroundColor: palette.background.default,
    width: 423,
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(2),
    position: 'relative',
  },
  basicDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(1),
    marginBottom: spacing(3),
  },
  title: {
    marginBottom: 30,
    display: "flex", 
    justifyContent: "space-between"
  },
  field: {
    marginBottom: 10,
  },
  button: {
    height: 48,
    backgroundColor: palette.primary.main,
    color: 'white',
    fontSize: 16,
    lineHeight: '24px',
    width: '100%',
    marginTop: 10,
  },
  buttonClear: {
    height: 48,
    backgroundColor: 'transparent',
    color: palette.primary.main,
    fontSize: 16,
    lineHeight: '24px',
    width: '100%',
    marginTop: 10,
    border: '1px solid #64A2C8',
    borderRadius: 10,
  },
  buttonRed: {
    height: 48,
    backgroundColor: 'transparent',
    color: "#FF0000",
    fontSize: 16,
    lineHeight: '24px',
    width: '100%',
    marginTop: 10,
    border: '1px solid #FF0000',
    borderRadius: 10,
  },
  btnsContainer: {
    position: 'absolute',
    bottom: 10,
    right: 20,
    left: 20,
  },
  img: {
    width: 20,
    height: 20,
    cursor: 'pointer',
    marginLeft: 10,
  },
  passwCont: {
    display: 'flex',
    flexDirection: 'row',
  },
  editTxt: {
    color: palette.primary.main,
    textDecoration: 'underline',
    position: 'absolute',
    right: 12,
    top: 28,
    cursor: 'pointer',
  },
  advancedDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
    marginBottom: spacing(2),
  },
  closeImg: {
    width: 20,
    height: 20,
    cursor: "pointer"
  },
}));

const GroupDetails = ({ link, selectedGroupId, onClose }: IProps) => {
  const classes = useStyles();
  const [group, setGroup] = useState<IGroup>();
  const [stats, setStats] = useState<Stats>({
    tests: 0,
    users: 0,
    testedPositive: 0,
  });
    
  const [activatePopupVisible, setActivatePopupVisible] = useState<boolean>(false);
  const [deactivatePopupVisible, setDeactivatePopupVisible] = useState<boolean>(false);
  const [authorisePopupVisible, setAuthorisePopupVisible] = useState<boolean>(false);
  const [deletePopupVisible, setDeletePopupVisible] = useState<boolean>(false);

  const { saveAsCsv } = useJsonToCsv();

  useEffect(() => {
    fetchGroup(link).then((e) => {
      setGroup(e);
    });
    fetchData(getTestsListLink(selectedGroupId)).then((testsList) => {
      setStats({
        tests: testsList.length,
        users: calculateNumberOfUsers(testsList),
        testedPositive: calculateTestedType(testsList, 'positive') as number,
      });
    });
  }, [link, selectedGroupId]);

  if (!group) {
    return null;
  }

  const exportPhotos = async () => {
    const photos = await downloadTestKits(selectedGroupId)

    const map = []
    photos.positive.forEach(p => map.push({ status: "Positive", photo: p }))
    photos.negative.forEach(p => map.push({ status: "Negative", photo: p }))
    photos.invalid.forEach(p => map.push({ status: "Invalid", photo: p }))

    saveAsCsv({
      data: map,
      fields: {
        status: "Status",
        photo: "Photo"
      },
      filename: 'photos',
    });
  }

  return (
    <Card className={classes.root}>
      <Box className={classes.basicDetails}>
        <Typography variant="h4" className={classes.title} >
          <b>{group.name}</b>
          <img className={classes.closeImg} alt="" src={closeIcon} onClick={onClose}/>
        </Typography>
        <Typography className={classes.field}>
          Country: {group.location}
        </Typography>
        <Typography className={classes.field}>
          Email: {group?.admin_email ? group.admin_email : '-'}
        </Typography>
        <Typography>Group ID: {group.employee_id}</Typography>
        <Typography>
          Group ID for Medical Professional:{' '}
          {group.medical_professional_id}
        </Typography>
      </Box>
      <Box className={classes.advancedDetails}>
        <DataSet label="Number of Tests" text={`${stats.tests || 0}`} />
        <DataSet label="Number of Users" text={`${stats.users || 0}`} />
        <DataSet
          label="Users Currently Tested Positive"
          text={`${stats.testedPositive || 0}`}
        />
      </Box>
      <Box className={classes.btnsContainer}>
        {
          group.status === "Pending" &&
          <Button
            fullWidth
            variant="contained"
            className={classes.buttonRed}
            size="large"
            onClick={() => setDeletePopupVisible(true)}
          >
            Delete Group
          </Button>
        }
        {
          group.status === "Pending" &&
          <Button
            fullWidth
            variant="contained"
            className={classes.buttonClear}
            size="large"
            onClick={() => setAuthorisePopupVisible(true)}
          >
            Authorise Group
          </Button>
        }
        {
          group.status === "Inactive" &&
          <Button
            fullWidth
            variant="contained"
            className={classes.buttonClear}
            size="large"
            onClick={() => setActivatePopupVisible(true)}
          >
            Activate Group
          </Button>
        }
        {
          group.status === "Active" &&
          <Button
            fullWidth
            variant="contained"
            className={classes.buttonClear}
            size="large"
            onClick={() => setDeactivatePopupVisible(true)}
          >
            Deactivate Group
          </Button>
        }
        {
          firebaseConfig.projectId === "test-camtech-app" && 
          <Button
            fullWidth
            variant="contained"
            className={classes.buttonClear}
            size="large"
            onClick={exportPhotos}
          >
            Download Testkit Files
          </Button>
        }
      </Box>
      {deletePopupVisible && (
        <UpdateGroupPopup
            onClose={() => {
              setDeletePopupVisible(false)
              onClose()
            }}
            firebaseCall={() => deleteGroup(selectedGroupId)}
            actionText="DELETE"
        />
      )}
      {authorisePopupVisible && (
        <UpdateGroupPopup
            onClose={() => setAuthorisePopupVisible(false)}
            firebaseCall={() => changeGroupStatus(selectedGroupId, "Active")}
            actionText="AUTHORISE"
        />
      )}
      {activatePopupVisible && (
        <UpdateGroupPopup
            onClose={() => setActivatePopupVisible(false)}
            firebaseCall={() => changeGroupStatus(selectedGroupId, "Active")}
            actionText="ACTIVATE"
        />
      )}
      {deactivatePopupVisible && (
        <UpdateGroupPopup
            onClose={() => setDeactivatePopupVisible(false)}
            firebaseCall={() => changeGroupStatus(selectedGroupId, "Inactive")}
            actionText="DEACTIVATE"
        />
      )}
    </Card>
  );
};

export default GroupDetails;
