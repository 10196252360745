import { MouseEvent, useState } from 'react';
import { Box, Menu, MenuItem, Button, Typography } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

export interface IDropdownOption {
  label: string;
  onClick: () => void;
  button?: boolean;
}

const useStyles = makeStyles(({ spacing }) => ({
  menuBox: {
    justifyContent: 'center',
  }
}));

interface IProps {
  label: string;
  options: IDropdownOption[];
}

const Dropdown = ({ label, options }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <Box>
      <Button
        endIcon={<KeyboardArrowDown />}
        onClick={handleClick}
        size="medium"
      >
        {label}
      </Button>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Button
          endIcon={<KeyboardArrowUp />}
          onClick={handleClose}
          size="medium"
        >
          {label}
        </Button>
        {options.map((option) => (
          <MenuItem
            onClick={handleClose}
            key={option.label}
            className={classes.menuBox}
          >
            {option.button ? (
              <Button
                color="primary"
                variant="contained"
                onClick={option.onClick}
              >
                {option.label}
              </Button>
            ) : (
              <Typography onClick={option.onClick}>{option.label}</Typography>
            )}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default Dropdown;
