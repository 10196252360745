export const DefaultFilters = {
  startDate: null,
  endDate: null,
  positive: true,
  negative: true,
  invalid: true,
  camtechTests: true,
  nonCamtechTests: true,
  corporateId: null
};

export const UserFilters = {
  startDate: null,
  endDate: null,
  positive: true,
  negative: true,
  invalid: true,
  antigen: true,
  pcr: true,
};