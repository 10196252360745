import {
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  confirmPasswordReset,
} from 'firebase/auth';

import { auth } from '../utils/firebase';

export const login = (email: string, password: string) =>
  signInWithEmailAndPassword(auth, email, password);

export const forgotPassword = (email) => {
  const url = window.location.href;
  const domain = new URL(url).origin;
  return sendPasswordResetEmail(auth, email, {
    url: `${domain}/`,
  });
};

export const resetPassword = (oobCode, newPassword) => {
  return confirmPasswordReset(auth, oobCode, newPassword);
};

export const logout = () => {
  signOut(auth);
};
