import { TableCell as MaterialTableCell, Typography } from '@material-ui/core';
import { get } from 'underscore';
import { ITableColumn, ITableRow } from '../../types/Table.types';

interface IProps {
  row: ITableRow;
  column: ITableColumn;
  className?: string;
}

const TableCell = ({ row, column, className }: IProps) => {
  let value = get(row, column.field.split('.'));
  if (column.changeValue) {
    value = column.changeValue(row);
  }

  return (
    <MaterialTableCell className={className}>
      <Typography variant="body2">{value}</Typography>
    </MaterialTableCell>
  );
};

export default TableCell;
