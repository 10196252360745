import { createContext, useEffect, useState } from 'react';
import { Box, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Table } from '../../components/Table';
import { ITableRow } from '../../types/Table.types';
import GroupDetails from './GroupDetails';
import { testsColumns } from './const';
import { getGroupListLink, getGroupItemLink } from '../../api/const';
import { fetchData } from '../../components/Table/api';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: spacing(2),
  },
  table: {
    overflow: 'auto',
    width: '100%',
  },
}));

export const GroupContext = createContext<{
  updateCompanies: boolean;
  setUpdateCompanies: (v) => void;
}>({ updateCompanies: false, setUpdateCompanies: (v) => null });

const Group = () => {
  const classes = useStyles();

  const [updateCompanies, setUpdateCompanies] = useState(false);

  const [tableData, setTableData] = useState<any[]>([]);

  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(
    null
  );
  const onRowClick = (row: ITableRow) =>
    isRowSelected(row)
      ? setSelectedGroupId(null)
      : setSelectedGroupId(row.id);
  const isRowSelected = (row: ITableRow) => row.id === selectedGroupId;

  const getGroups = async () => {
    const fetchedRows = await fetchData(getGroupListLink(), {
      name: 'created_at',
      order: 'desc',
    });
    setTableData(fetchedRows);
  };

  useEffect(() => {
    getGroups();
  }, []);

  return (
    <GroupContext.Provider value={{ updateCompanies, setUpdateCompanies }}>
      <Box className={classes.root}>
        <Card className={classes.table}>
          <Table
            update={updateCompanies}
            columns={testsColumns}
            link={getGroupListLink()}
            onRowClick={onRowClick}
            isRowSelected={isRowSelected}
            tableData={tableData}
            setTableData={setTableData}
            sortData={{
              name: 'created_at',
              order: 'desc',
            }}
          />
        </Card>
        {selectedGroupId && (
          <GroupDetails
            link={getGroupItemLink(selectedGroupId)}
            selectedGroupId={selectedGroupId}
            onClose={() => setSelectedGroupId(null)}
          />
        )}
      </Box>
    </GroupContext.Provider>
  );
};

export default Group;
