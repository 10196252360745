import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import React, { useState, useEffect } from 'react';
import okIcon from '../../icons/okIcon.png';
import travelIcon from '../../icons/travel.png';
import vacinationIcon from '../../icons/vacination.png';
import backIcon from '../../icons/backIcon.png';
import { Box, Typography } from '@material-ui/core';
import { ITest } from '../../types/Test.types';
import clsx from 'clsx';
import { getTestResult, formatDateTime } from '../../utils';

import { fetchTest } from '../Tests/api';

const useStyles = makeStyles(({ spacing, palette }) => ({
  topInput: {
    marginBottom: spacing(1),
  },
  button: {
    height: 48,
    backgroundColor: palette.primary.main,
    color: 'white',
    fontSize: 16,
    lineHeight: '24px',
    width: '100%',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: '100%',
    backgroundColor: 'white',
    height: '100%',
    top: 90,
    right: 0,
    left: 125,
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  header: {
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 37,
  },
  headerTxt: {
    fontSize: 48,
    lineHeight: ' 130%',
    fontWeight: 'bold',
    color: palette.text.primary,
    marginLeft: 20,
  },
  title: {
    color: palette.text.primary,
    fontSize: 30,
    fontWeight: 'bold',
    lineHeight: '40px',
    padding: 30,
  },
  backImg: {
    width: 40,
    height: 40,
    cursor: 'pointer',
  },
  groupForm: {
    padding: '10px 30px',
  },
  buttonsContainer: {
    position: 'absolute',
    bottom: 20,
    right: 30,
    left: 30,
  },
  fieldLable: {
    color: palette.primary.main,
    paddingTop: 12,
  },
  secondaryMenuCont: {
    display: 'flex',
    flexDirection: 'row',
    margin: 15,
  },
  bigMenuCont: {
    width: 548,
    height: 193,
    boxShadow: '2px 5px 15px rgba(71, 104, 122, 0.15)',
    borderRadius: 5,
    margin: 15,
    padding: 30,
    position: 'relative',
  },
  smallMenuCont: {
    width: 366,
    height: 289,
    boxShadow: '2px 5px 15px rgba(71, 104, 122, 0.15)',
    borderRadius: 5,
    margin: 15,
    padding: 15,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  bigMenuHeader: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 500,
    color: palette.text.primary,
    marginBottom: 20,
  },
  smallMenuHeader: {
    fontSize: 20,
    lineHeight: '28px',
    fontWeight: 500,
    color: palette.text.primary,
    marginBottom: 20,
  },
  okImg: {
    position: 'absolute',
    right: 20,
    top: 30,
    width: 100,
    height: 100,
  },
  detailsBox: {
    display: 'flex',
  },
  child: {
    flex: 'auto',
  },
  lable: {
    maxWidth: 100,
  },
  longLable: {
    maxWidth: 170,
    width: 170,
  },
  detailsChild: {
    textAlign: 'left',
    color: palette.text.primary,
  },
  advancedDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '10px',
  },
  menuBorder: {
    border: '1px solid #EBF5F8',
    borderRadius: 4,
    padding: '6px 10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '10px',
  },
  bottomButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 'auto',
    justifySelf: 'flex-end',
    cursor: 'pointer',
  },
  bottomButtonNonClickable: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 'auto',
    justifySelf: 'flex-end',
  },
  bottomButtonTxt: {
    color: palette.primary.main,
  },
  commingSoonIcon: {
    height: 200,
    width: 200,
    margin: '0 83px',
  },
  commingSoonIconTravel: {
    height: 200,
    width: 122,
    margin: '0 121px',
  },
}));

function getModalStyle() {
  const right = 0;
  return {
    right: `${right}%`,
  };
}

interface IProps {
  isOpen: boolean;
  onClose?: () => void;
  link: string;
  setUserProfileVisible: () => void;
}

interface IDataSetProps {
  label: string;
  text: string;
  longLable?: boolean;
}

const UserProfile = ({
  isOpen,
  onClose,
  link,
  setUserProfileVisible,
}: IProps) => {
  const classes = useStyles();
  const [user, setUser] = useState<ITest>();

  const [modalStyle] = React.useState(getModalStyle);

  useEffect(() => {
    fetchTest(link).then(setUser);
  }, [link]);

  if (!user) {
    return null;
  }

  const DataSet = ({ label, text, longLable }: IDataSetProps) => (
    <Box className={classes.detailsBox}>
      <Box
        className={clsx(
          classes.child,
          classes.lable,
          longLable && classes.longLable
        )}
      >
        <Typography color="primary">{label}</Typography>
      </Box>
      <Box className={clsx(classes.detailsChild, classes.child)}>
        <Typography>{text}</Typography>
      </Box>
    </Box>
  );

  const testPerformer =
    user.medical_professional === null
      ? `${user.patient.first_name} ${
          user.patient.last_name
        }, User, ${formatDateTime(user.submitted_at)}`
      : `${user.medical_professional.first_name} ${
          user.medical_professional.last_name
        }, Medical Professional, ${formatDateTime(user.submitted_at)}`;

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isOpen}
      onClose={onClose}
      BackdropProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <div style={modalStyle} className={classes.paper}>
        <Box className={classes.header}>
          <img
            src={backIcon}
            alt="back"
            className={classes.backImg}
            onClick={onClose}
          />
          <Typography className={classes.headerTxt}>
            User Profile
          </Typography>
        </Box>

        <Box className={classes.secondaryMenuCont}>
          <Box className={classes.bigMenuCont}>
            <Typography className={classes.bigMenuHeader}>Details</Typography>
            <Box className={classes.advancedDetails}>
              <DataSet
                label="Name"
                text={`${user.patient.first_name} ${user.patient.last_name}`}
              />
              {user.patient.email && (
                <DataSet label="Email" text={user.patient.email} />
              )}
              {user.patient.contact_number && (
                <DataSet
                  label="Contact"
                  text={user.patient.contact_number}
                />
              )}
              {user.patient.job_title && (
                <DataSet
                  label="Dept / Job Title"
                  text={user.patient.job_title}
                />
              )}
            </Box>
          </Box>

          <Box className={classes.bigMenuCont}>
            <Typography className={classes.bigMenuHeader}>
              Overall Status
            </Typography>
            {user.patient.job_title && (
              <DataSet
                longLable
                label="Latest Test Results"
                text={getTestResult(user)}
              />
            )}
            <img src={okIcon} alt="ok" className={classes.okImg} />
          </Box>
        </Box>

        <Box className={classes.secondaryMenuCont}>
          <Box className={classes.smallMenuCont}>
            <Typography className={classes.smallMenuHeader}>
              Latest Test Results
            </Typography>
            <Box className={classes.menuBorder}>
              <DataSet
                longLable
                label="Test Results"
                text={getTestResult(user)}
              />
              <DataSet label="Test Type" longLable text={user.type} />
              <DataSet
                label="Test Date"
                longLable
                text={formatDateTime(user.submitted_at)}
              />
              <DataSet
                label="Submitted by"
                longLable
                text={
                  user.medical_professional?.job_title
                    ? 'Medical Professional'
                    : 'User'
                }
              />
              <Typography variant="body2" color="textSecondary">
                {testPerformer}
              </Typography>
            </Box>
            <Box
              className={classes.bottomButton}
              onClick={setUserProfileVisible}
            >
              <Typography className={classes.bottomButtonTxt}>
                {'See history of results >>'}
              </Typography>
            </Box>
          </Box>

          <Box className={classes.smallMenuCont}>
            <Typography className={classes.smallMenuHeader}>
              Covid-19 Vaccination
            </Typography>
            <img
              src={vacinationIcon}
              alt="vacinationIcon"
              className={classes.commingSoonIcon}
            />
            <Box className={classes.bottomButtonNonClickable}>
              <Typography className={classes.bottomButtonTxt}>
                Coming soon
              </Typography>
            </Box>
          </Box>

          <Box className={classes.smallMenuCont}>
            <Typography className={classes.smallMenuHeader}>
              Travel History
            </Typography>
            <img
              src={travelIcon}
              alt="travelIcon"
              className={classes.commingSoonIconTravel}
            />
            <Box className={classes.bottomButtonNonClickable}>
              <Typography className={classes.bottomButtonTxt}>
                Coming soon
              </Typography>
            </Box>
          </Box>
        </Box>
      </div>
    </Modal>
  );
};

export default UserProfile;
