import React, { useState, createContext, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { onAuthStateChanged, User } from 'firebase/auth';
import {
  BrowserRouter as Router, Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';

import Login from './pages/Login';
import Layout from './components/Layout';
import { auth } from './utils/firebase';
import { fetchUser } from './api/user';
import { IUser } from './types/User.types';
import Loader from './components/Loader';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

export const UserContext = createContext<IUser | null>(null);

function App() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<IUser | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (result: User | null) => {
      if (result) {
        fetchUser(result.uid).then((user) => {
          setUser(user);
          setLoading(false);
        });
      } else {
        setLoading(false);
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Router>
      <Box>
        {user ? (
          <UserContext.Provider value={user}>
            <Layout />
          </UserContext.Provider>
        ) : (
          <Switch>
            <Route exact path="/" component={withRouter(Login)} />
            <Route
              path="/forgot-password"
              component={withRouter(ForgotPassword)}
            />
            <Route
              path="/reset-password"
              component={withRouter(ResetPassword)}
            />
		      	<Redirect to="/" />
          </Switch>
        )}
      </Box>
    </Router>
  );
}

export default App;
