import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(3),
  },
  title: {
    fontWeight: 'bold',
  },
}));

const About = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography color="textPrimary" variant="h5">
        About Camtech
      </Typography>
      <Typography variant="h5" className={classes.title} color="primary">
        Enabling ideal human health and wellness through technology and
        innovation in testing.
      </Typography>
      <Typography color="textPrimary">
        Camtech Diagnostics was founded in 2013 and has built significant
        R&amp;D and Manufacturing capabilities to support these developments as
        an integrated diagnostics provider.
      </Typography>

      <Typography color="textPrimary">
        Our detection platforms provide the basis for reliability, customisation
        and scalability in delivering rapid testing solutions. Camtech is
        offering a range of laboratory and rapid tests to increase COVID-19
        testing around the world.
      </Typography>
    </Box>
  );
};

export default About;
