import { useContext, useState, useEffect, useCallback } from 'react';
import { Box, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Table } from '../../components/Table';
import { ITableRow } from '../../types/Table.types';
import TestDetails from './TestDetails';
import { UserContext } from '../../App';
import { ITest } from '../../types/Test.types';
import {
  exportTestsFields,
  getTestExportRow,
  testsColumns,
  testsColumnsAdmin,
} from './const';
import TestsHeader from './TestsHeader';
import { getTestsListLink, getTestItemLink } from '../../api/const';

import { firebaseConfig } from '../../const/firebase.config';
import { DefaultFilters } from '../../const/filters';
import { filterData } from '../../functions/filter';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: spacing(2),
  },
  table: {
    overflow: 'auto',
    width: '100%',
  },
}));

const Tests = () => {
  const classes = useStyles();
  const user = useContext(UserContext);

  const [tableData, setTableData] = useState<ITest[]>([]);
  const [isAdmin, setIsAdmin] = useState<boolean>(user.corporation === 'ALL');
  const [filteredTableData, setFilteredTableData] = useState<ITest[]>([]);
  const [filters, setFilters] = useState<any>(DefaultFilters);
  const [selectedTestId, setSelectedTestId] = useState<string | null>(null);
  const [selectedTestGroupId, setSelectedTestGroupId] = useState<
    string | null
  >(null);

  const onRowClick = (row: ITableRow) => {
    if (isAdmin) setSelectedTestGroupId(row.corporate_id);
    isRowSelected(row) ? setSelectedTestId(null) : setSelectedTestId(row.id);
  };
  const isRowSelected = (row: ITableRow) => row.id === selectedTestId;

  const handleFilterFieldValueChange = (val: Object) => {
    setFilters({ ...filters, ...val });
  };

  const clearSelectedFilters = () => {
    setFilters(DefaultFilters);
    setFilteredTableData(tableData);
  };

  const setData = useCallback((arr: any) => {
    setTableData(arr);
    setFilteredTableData(arr);
  }, []);

  useEffect(() => {
    setFilteredTableData(filterData(tableData, filters));
    // login
  }, [filters, tableData]);

  useEffect(() => {
    if (user.corporation === 'ALL') {
      setIsAdmin(true);
    }
  }, [user]);

  return (
    <Box className={classes.root}>
      <Card className={classes.table}>
        {tableData.length > 0 && (
          <TestsHeader
            tests={filteredTableData}
            getTestExportRow={getTestExportRow}
            exportTestsFields={exportTestsFields}
            linkForPdfReport={`https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net/generateTestReport?group_id=${user.corporation}`}
            areFiltersOnThePage
            filtersData={DefaultFilters}
            filters={filters}
            handleFilterFieldValueChange={handleFilterFieldValueChange}
            clearSelectedFilters={clearSelectedFilters}
          />
        )}

        <Table
          columns={isAdmin ? testsColumnsAdmin : testsColumns}
          link={getTestsListLink(user.corporation)}
          getAllTests={isAdmin}
          onRowClick={onRowClick}
          isRowSelected={isRowSelected}
          tableData={filteredTableData}
          setTableData={setData}
        />
      </Card>
      {selectedTestId && isAdmin && (
        <TestDetails
          link={getTestItemLink(selectedTestGroupId, selectedTestId)}
          onClose={() => setSelectedTestId(null)}
        />
      )}
      {selectedTestId && !isAdmin && (
        <TestDetails 
          link={getTestItemLink(user.corporation, selectedTestId)} 
          onClose={() => setSelectedTestId(null)}
        />
      )}
    </Box>
  );
};

export default Tests;
