import { ITableColumn } from '../../types/Table.types';
import { ISymptom, ITest } from '../../types/Test.types';
import { formatDateTime, getTestResult } from '../../utils';

export const testsColumns: ITableColumn[] = [
  {
    title: 'First Name',
    field: 'patient.first_name',
    sortable: true,
  },
  {
    title: 'Last Name',
    field: 'patient.last_name',
    sortable: true,
  },
  {
    title: 'Email',
    field: 'patient.email',
    sortable: true,
  },
  {
    title: 'Job Title / Dept',
    field: 'patient.job_title',
    sortable: true,
  },
  {
    title: 'Type',
    field: 'type',
    sortable: true,
  },
  {
    title: 'Test Results',
    field: 'result',
    changeValue: (row: ITest) => getTestResult(row),
  },
  {
    title: 'Test Date',
    field: 'submitted_at',
    sortable: true,
    changeValue: (row: ITest) => formatDateTime(row.submitted_at),
  },
];

export const testsColumnsAdmin: ITableColumn[] = [
  {
    title: 'First Name',
    field: 'patient.first_name',
    sortable: true,
  },
  {
    title: 'Last Name',
    field: 'patient.last_name',
    sortable: true,
  },
  {
    title: 'Email',
    field: 'patient.email',
    sortable: true,
  },
  {
    title: 'Job Title / Dept',
    field: 'patient.job_title',
    sortable: true,
  },
  {
    title: 'Group ID',
    field: 'employee_id',
    sortable: false,
  },
  {
    title: 'Type',
    field: 'type',
    sortable: true,
  },
  {
    title: 'Test Results',
    field: 'result',
    changeValue: (row: ITest) => getTestResult(row),
  },
  {
    title: 'Test Date',
    field: 'submitted_at',
    sortable: true,
    changeValue: (row: ITest) => formatDateTime(row.submitted_at),
  },
];

export const exportTestsFields = {
  firstName: `"First Name"`,
  lastName: `"Last Name"`,
  email: `"Email"`,
  jobTitle: `"Job Title / Dept"`,
  age: `"Age"`,
  sex: `"Sex"`,
  type: `"Test Type"`,
  brand: `"Test Brand"`,
  result: `"Test Result"`,
  date: `"Test Date"`,
  corporateId: `"Group ID"`,
  symptomBlockedNose: `"Symptom: Blocked Nose"`,
  symptomCough: `"Symptom: Cough"`,
  symptomFever: `"Symptom: Fever"`,
  symptomRunningNose: `"Symptom: Running Nose"`,
  symptomSoreThroat: `"Symptom: Sore Throat"`,
  symptomOther: `"Symptom: Other"`,
};

export const getTestExportRow = (test: ITest) => ({
  id: `"${test.id}"`,
  firstName: `"${test.patient.first_name}"`,
  lastName: `"${test.patient.last_name}"`,
  email: `"${test.patient.email}"`,
  jobTitle: `"${test.patient.job_title ?? "-"}"`,
  age: `"${test.patient.age ?? "-"}"`,
  sex: `"${test.patient.sex ?? "-"}"`,
  type: `"${test.type}"`,
  brand: `"${test.brand}"`,
  result: `"${getTestResult(test)}"`,
  date: `"${formatDateTime(test.submitted_at)}"`,
  corporateId: `"${test.employee_id ?? "-"}"`,
  symptomBlockedNose: `"${(test.symptoms && test.symptoms?.find((s: ISymptom) => s.symptom === "Blocked Nose")?.severity) ?? ""}"`,
  symptomCough: `"${(test.symptoms && test.symptoms?.find((s: ISymptom) => s.symptom === "Cought")?.severity) ?? ""}"`,
  symptomFever: `"${(test.symptoms && test.symptoms?.find((s: ISymptom) => s.symptom === "Fever")?.severity) ?? ""}"`,
  symptomRunningNose: `"${(test.symptoms && test.symptoms?.find((s: ISymptom) => s.symptom === "Running Nose")?.severity) ?? ""}"`,
  symptomSoreThroat: `"${(test.symptoms && test.symptoms?.find((s: ISymptom) => s.symptom === "Sore Throat")?.severity) ?? ""}"`,
  symptomOther: `"${(test.symptoms && test.symptoms?.find((s: ISymptom) => s.symptom === "Other")?.description) ?? ""}"`,
});
