import React, { FormEvent, useState } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { resetPassword } from '../../api';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    textAlign: 'center',
    margin: '0 auto',
    maxWidth: 500,
    height: '100%',
    color: palette.text.primary,
  },
  logoContainer: {
    marginTop: spacing(15),
    marginBottom: spacing(9),
  },
  loginLink: {
    textDecoration: 'none',
    color: palette.primary.main,
  },
  button: {
    height: 48,
    marginBottom: spacing(8),
  },
  error: {
    color: '#FF0000',
    left: 10,
    position: 'relative'
  },
}));

const ResetPassword = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const query = new URLSearchParams(location.search);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    resetPassword(query.get('oobCode'), password)
      .then(() => {
        history.push('/');
      })
      .catch((error) => setError(error.message.replace("Firebase: ", "")));
  };

  return (
    <Box className={classes.root}>
      <form onSubmit={onSubmit}>
        <Grid container direction="column" spacing={2}>
          <Grid item className={classes.logoContainer}>
            <img src="/logo.png" alt="Camtech" />
          </Grid>
          <Grid item>
            <Typography align="left" variant="h4">
              <b>Reset password</b>
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
          { error  && <Typography className={classes.error}>{ error }</Typography> }
          <Grid item>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              type="submit"
              className={classes.button}
            >
              Reset password
            </Button>
          </Grid>
          <Grid item>
            <RouterLink className={classes.loginLink} to="/">
              <Typography>Go to login</Typography>
            </RouterLink>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default ResetPassword;
