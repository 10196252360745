import React, { FormEvent, useState } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { forgotPassword } from '../../api';
import {validateEmail} from '../../utils/validate';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    textAlign: 'center',
    margin: '0 auto',
    maxWidth: 500,
    height: '100%',
    color: palette.text.primary,
  },
  logoContainer: {
    marginTop: spacing(15),
    marginBottom: spacing(9),
  },
  loginLink: {
    textDecoration: 'none',
    color: palette.primary.main,
  },
  successInfo: {
    marginBottom: spacing(9),
  },
  button: {
    height: 48,
    marginBottom: spacing(8),
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setEmailError('');
    if (!validateEmail(email))
      setEmailError('Please enter a valid email address');
    forgotPassword(email)
      .then(() => setSubmitted(true))
      .catch((error) => {
			if(error.code === 'auth/user-not-found') {
			  setEmailError(`No matching user found, please check your email and try again`)
			} else {
			  setEmailError(`Sorry, an unexpected error has occurred during sign up: ${error}`)
			}
	  });
  };

  return (
    <Box className={classes.root}>
      <form onSubmit={onSubmit}>
        <Grid container direction="column" spacing={2}>
          <Grid item className={classes.logoContainer}>
            <img src="/logo.png" alt="Camtech" />
          </Grid>
          {!submitted ? (
            <>
              <Grid item>
                <Typography align="left" variant="h4">
                  <b>Forgot password</b>
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Email to reset password"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={emailError.length > 0}
                  helperText={emailError}
                />
              </Grid>
              <Grid item>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  size="large"
                  type="submit"
                  className={classes.button}
                >
                  Submit
                </Button>
              </Grid>
            </>
          ) : (
            <Grid className={classes.successInfo} item>
              <Typography align="left" variant="h5">
                We have sent you email with verifying link
              </Typography>
            </Grid>
          )}
          <Grid item>
            <RouterLink className={classes.loginLink} to="/">
              <Typography>Go to login</Typography>
            </RouterLink>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default ForgotPassword;
