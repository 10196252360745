import { FormEvent, useState } from 'react';
import {
  Grid,
  Box,
  TextField,
  Button,
  Typography,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';

import { login } from '../../api';
import { firebaseConfig } from '../../const/firebase.config';
import {validateEmail} from '../../utils/validate';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    textAlign: 'center',
    margin: '0 auto',
    maxWidth: 450,
    height: '100%',
    color: palette.text.primary,
  },
  logoContainer: {
    marginTop: spacing(15),
    marginBottom: spacing(9),
  },
  logo: {
    width: '100%',
  },
  topInput: {
    marginBottom: spacing(1),
  },
  marginStyle: {
    marginBottom: spacing(9),
  },
  forgotPasswordLink: {
    textDecoration: 'none',
    color: palette.text.primary,
  },
  button: {
    height: 48,
  },
  red: {
    color: '#FF0000',
  },
  signUpLink: {
    textDecoration: 'none',
    color: palette.primary.main,
  },
}));

const Login = () => {
  const classes = useStyles();

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setEmailError('');
    setPasswordError('');
    if (!validateEmail(email))
      setEmailError('Please enter a valid email address');
    else if (password.length === 0) setPasswordError('Please enter a password');
    else
      login(email, password).catch((error) => {
        setPasswordError(
          "The email or password that you've entered is incorrect"
        );
      });
  };

  return (
    <Box className={classes.root}>
      <form onSubmit={handleLogin}>
        <Grid container direction="column" spacing={2}>
          <Grid item className={classes.logoContainer}>
            {firebaseConfig.projectId === 'test-camtech-app' && (
              <Typography className={classes.red}>Test environment</Typography>
            )}
            <img src="/logo.png" alt="Camtech" className={classes.logo} />
            <Typography variant='h5'>
              Group Admin Log In
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Email"
              type="email"
              className={classes.topInput}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={emailError.length > 0}
              helperText={emailError}
            />
            <TextField
              variant="outlined"
              fullWidth
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={passwordError.length > 0}
              helperText={passwordError}
            />
          </Grid>
          <Grid item>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              type="submit"
              className={classes.button}
            >
              Log in
            </Button>
          </Grid>
          <Grid item className={classes.marginStyle}>
            <RouterLink
              className={classes.forgotPasswordLink}
              to="/forgot-password"
            >
              <Typography>Forgot your password?</Typography>
            </RouterLink>
          </Grid>
          <Grid item >
            <Typography>
              Don't have an admin login? 
            </Typography>
          </Grid>
          <Grid item className={classes.marginStyle}>
            <Typography variant='caption'>
              <i>Only Group admins can log in to the web portal. You can download the Camtech Health app and create a group from there. You will then be able to login here with your email and password.</i>
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              By logging in you agree to{' '}
              <Link
                href="http://camtech.org/assets/app/CamtechAppTnC.html"
                target="_blank"
                rel="noopener"
              >
                Terms of Use
              </Link>{' '}
              and{' '}
              <Link
                href="http://camtech.org/assets/app/CamtechAppPP.html"
                target="_blank"
                rel="noopener"
              >
                Privacy Policy
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Login;
