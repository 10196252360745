import { Box, Typography, Button, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import contactBackground from '../../../../icons/contactBackground.png';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: spacing(1),
    flexGrow: 1,
  },
  image: {
    width: 300,
    height: 300,
    marginBottom: spacing(9),
  },
  title: {
    fontWeight: 'bold',
    marginTop: spacing(1),
  },
  button: {
    padding: `${spacing(1.5)}px ${spacing(10)}px`,
    marginTop: spacing(2),
  },
}));

const Contact = () => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root)}>
      <img src={contactBackground} alt="img" className={clsx(classes.image)} />
      <Typography color="textPrimary" variant="h5">
        Contact Us
      </Typography>

      <Typography variant="h5" className={classes.title} color="primary">
        Camtech Diagnostics
      </Typography>

      <Typography color="textPrimary">
        5 Jalan Kilang Barat, #01-01, Petro Centre, Singapore, 159349
      </Typography>

      <Typography>
        <Link
          href="mailto:info@camtech.org"
          target="_blank"
          rel="nofollow noopener"
        >
          info@camtech.org
        </Link>
      </Typography>

      <Button
        color="primary"
        variant="contained"
        className={classes.button}
        href="mailto:info@camtech.org"
        target="_blank"
        rel="nofollow noopener"
      >
        Send Us Email
      </Button>
    </Box>
  );
};

export default Contact;
