import { Box, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ExportIcon from '../../icons/export.png';
import AddIcon from '../../icons/plusIcon.png';
import CloseIcon from '../../icons/close.png';
import { ITest } from '../../types/Test.types';
import FilterPopup from '../../components/FilterPopup';
import FilterDatesPopup from '../../components/FilterDatesPopup';
import React, { useState, MouseEvent, useEffect } from 'react';
import ExportResultsDropdown from '../../components/ExportResultsDropdown';
import ReportsExportLimitModal from '../../components/ReportsExportLimitModal';
import { useJsonToCsv } from 'react-json-csv';
import ReportIsBeingGeneratedPopup from '../../components/ReportIsBeingGeneratedPopup';
import ReportIsGeneratedPopup from '../../components/ReportIsGeneratedPopup';

interface IProps {
  tests: ITest[];
  getTestExportRow: any;
  exportTestsFields: any;
  linkForPdfReport?: string;
  areFiltersOnThePage?: boolean;
  filtersData?: any;
  filters?: any;
  handleFilterFieldValueChange?: any;
  clearSelectedFilters?: any;
}
const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addFilterBtn: {
    margin: spacing(2),
    backgroundColor: '#E5F1F6',
    color: '#3D75A3',
    width: 127,
  },
}));

const TestsHeader = ({
  tests,
  getTestExportRow,
  exportTestsFields,
  linkForPdfReport,
  areFiltersOnThePage,
  filtersData,
  filters,
  handleFilterFieldValueChange,
  clearSelectedFilters,
}: IProps) => {
  const classes = useStyles();

  const [anchorElFilterPopup, setAnchorElFilterPopup] =
    useState<HTMLElement | null>(null);
  const [anchorElFilterDatesPopup, setAnchorElFilterDatesPopup] =
    useState<HTMLElement | null>(null);
  const [anchorElExportPopup, setAnchorElExportPopup] =
    useState<HTMLElement | null>(null);
  const [maxNumberOfReportsPopupVisible, setMaxNumberOfReportsPopupVisible] =
    useState<boolean>(false);
  const [
    reportsIsBeignGeneratedPopupVisible,
    setReportsIsBeingGeneratedPopupVisible,
  ] = useState<boolean>(false);
  const [reportsIsGeneratedPopupVisible, setReportsIsGeneratedPopupVisible] =
    useState<boolean>(false);
  const [pdfReportLink, setPdfReportLink] = useState<string>('');

  const [appliedFiltersCount, setAppliedFiltersCount] = useState<Number>(0);
  const [appliedFiltersLink, setAppliedFiltersLink] = useState<string>('');

  const positiveResultsCount = tests.filter(
    (test) => test.positive === true && test.valid
  ).length;

  const totalResultsCount = tests.length;

  const handleFilterPopupClick = (event: MouseEvent<HTMLSpanElement>) => {
    setAnchorElFilterPopup(event.currentTarget);
  };

  const handleFilterPopupClose = () => {
    setAnchorElFilterPopup(null);
  };

  const handleExportPopupClick = (event: MouseEvent<HTMLSpanElement>) => {
    setAnchorElExportPopup(event.currentTarget);
  };
  const handleExportPopupClose = () => {
    setAnchorElExportPopup(null);
  };

  const chooseExportoption = (opt: string) => {
    onExportClick(opt);
    handleExportPopupClose();
  };

  const onExportClick = (opt: string) => {
    if (tests.length > 9999) {
      setMaxNumberOfReportsPopupVisible(true);
    } else {
      if (opt === 'csv') {
        console.log(tests)
        saveAsCsv({
          data: tests.map(getTestExportRow),
          fields: exportTestsFields,
          filename: 'report',
        });
      } else {
        setReportsIsBeingGeneratedPopupVisible(true);
        getPdfFile();
      }
    }
  };

  const getPdfFile = () => {
    fetch(appliedFiltersLink, {
      method: 'GET',
    })
      .then((response) => response.text())
      .then((result) => setPdfReportLink(JSON.parse(result).url))
      .catch((error) => {});
  };

  useEffect(() => {
    if (areFiltersOnThePage) {
      let i = 7;
      for (let key in filters) {
        if (!filters[key]) {
          i--;
        }
      }
      setAppliedFiltersCount(i);

      function formatDate(date: string) {
        var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
      }

      const status = [];
      const types = [];
      let appliedFilters = '';
      
      if(filters.corporateId) {
        appliedFilters += '&employee_id=' + filters.corporateId
      }

      if (filters.negative) {
        status.push('negative');
      }
      if (filters.invalid) {
        status.push('invalid');
      }
      if (filters.positive) {
        status.push('positive');
      }
      if (status.length) {
        appliedFilters += '&status=' + status.join(',');
      }

      if (filters.startDate) {
        const date = formatDate(filters.startDate);
        appliedFilters += `&start=${date}`;
      }
      if (filters.endDate) {
        const date = formatDate(filters.endDate);
        appliedFilters += `&end=${date}`;
      }

      if (filters.camtechTests) {
        types.push('camtech');
      }
      if (filters.nonCamtechTests) {
        types.push('noncamtech');
      }
      if (types.length) {
        appliedFilters += '&brand=' + types.join(',');
      }

      setAppliedFiltersLink(`${linkForPdfReport}${appliedFilters}`);
    }
  }, [filters, linkForPdfReport, areFiltersOnThePage]);

  const clearPdfReportsLink = () => {
    setPdfReportLink('');
  };

  useEffect(() => {
    if (pdfReportLink) {
      setReportsIsGeneratedPopupVisible(true);
    }
  }, [pdfReportLink]);

  const openDatesPopup = () => {
    setAnchorElFilterDatesPopup(anchorElFilterPopup);
    setAnchorElFilterPopup(null);
  };

  const handleExportDatesPopupClose = () => {
    setAnchorElFilterPopup(anchorElFilterDatesPopup);
    setAnchorElFilterDatesPopup(null);
  };

  const handleFilterDatesPopupClick = (event: MouseEvent<HTMLSpanElement>) => {
    setAnchorElFilterDatesPopup(event.currentTarget);
  };

  const compareFilters = () => {
    return JSON.stringify(filters) === JSON.stringify(filtersData);
  };

  const { saveAsCsv } = useJsonToCsv();

  return (
    <Box className={classes.root}>
      <Typography variant="body2">
        {totalResultsCount > 0
          ? ((positiveResultsCount / totalResultsCount) * 100).toFixed(0)
          : 0}
        % ({positiveResultsCount} results) out of {totalResultsCount} results
        are tested positive
      </Typography>
      <Box>
        {areFiltersOnThePage && (
          <React.Fragment>
            {compareFilters() ? (
              <Button
                startIcon={<img src={AddIcon} alt="" />}
                variant="contained"
                onClick={handleFilterPopupClick}
                className={classes.addFilterBtn}
              >
                Add Filter
              </Button>
            ) : (
              <Button
                endIcon={
                  <img
                    src={CloseIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFilterPopupClose();
                      clearSelectedFilters();
                    }}
                    alt=""
                  />
                }
                onClick={handleFilterPopupClick}
                variant="contained"
                className={classes.addFilterBtn}
              >
                Filters ({appliedFiltersCount})
              </Button>
            )}
          </React.Fragment>
        )}
        <Button
          startIcon={<img src={ExportIcon} alt="" />}
          variant="contained"
          color="primary"
          onClick={handleExportPopupClick}
        >
          Export Report
        </Button>
        {areFiltersOnThePage && (
          <FilterPopup
            anchorEl={anchorElFilterPopup}
            handleClose={handleFilterPopupClose}
            handleClick={handleFilterPopupClick}
            openDatesPopup={openDatesPopup}
            handleFilterFieldValueChange={handleFilterFieldValueChange}
            clearSelectedFilters={clearSelectedFilters}
            filters={filters}
          />
        )}
        <ExportResultsDropdown
          anchorEl={anchorElExportPopup}
          handleClose={handleExportPopupClose}
          handleClick={handleExportPopupClick}
          chooseExportoption={chooseExportoption}
        />
        {areFiltersOnThePage && (
          <FilterDatesPopup
            anchorEl={anchorElFilterDatesPopup}
            handleClose={handleExportDatesPopupClose}
            handleClick={handleFilterDatesPopupClick}
            handleFilterFieldValueChange={handleFilterFieldValueChange}
            filters={filters}
          />
        )}
      </Box>
      {maxNumberOfReportsPopupVisible && (
        <ReportsExportLimitModal
          maxNumberOfReportsPopupVisible={maxNumberOfReportsPopupVisible}
          onClose={() => setMaxNumberOfReportsPopupVisible(false)}
        />
      )}
      {reportsIsBeignGeneratedPopupVisible && (
        <ReportIsBeingGeneratedPopup
          reportsIsBeignGeneratedPopupVisible={
            reportsIsBeignGeneratedPopupVisible
          }
          onClose={() => setReportsIsBeingGeneratedPopupVisible(false)}
        />
      )}
      {reportsIsGeneratedPopupVisible && (
        <ReportIsGeneratedPopup
          reportsIsGeneratedPopupVisible={reportsIsGeneratedPopupVisible}
          onClose={() => setReportsIsGeneratedPopupVisible(false)}
          pdfReportLink={pdfReportLink}
          clearPdfReportsLink={clearPdfReportsLink}
        />
      )}
    </Box>
  );
};

export default TestsHeader;
