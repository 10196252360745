import { Box, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
    flexGrow: 1,
  },
}));

const Terms = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography variant="h5" color="textPrimary">
        Terms and Conditions
      </Typography>

      <Typography color="textPrimary">
        Please click{' '}
        <Link
          href="http://camtech.org/assets/app/CamtechAppTnC.html"
          target="_blank"
          rel="nofollow noopener"
        >
          here
        </Link>{' '}
        to view the terms and conditions.
      </Typography>
      <Typography variant="h5" color="textPrimary">
        Privacy Policy
      </Typography>

      <Typography color="textPrimary">
        Please click{' '}
        <Link
          href="http://camtech.org/assets/app/CamtechAppPP.html"
          target="_blank"
          rel="nofollow noopener"
        >
          here
        </Link>{' '}
        to view the privacy policy.
      </Typography>
    </Box>
  );
};

export default Terms;
