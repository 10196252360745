import {
  TableCell as MaterialTableCell,
  Typography,
  Box,
} from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { ISort, ITableColumn } from '../../types/Table.types';

interface IProps {
  sort: ISort;
  column: ITableColumn;
  className?: string;
  onSort?: (sort: ISort) => void;
}

const useStyles = makeStyles(({ palette }) => ({
  sortableCell: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
}));

const TableHeaderCell = ({ sort, column, className, onSort }: IProps) => {
  const classes = useStyles();

  const sortTable = (name: string) => {
    if (sort && sort.name === name) {
      const order = sort.order === 'asc' ? 'desc' : 'asc';

      onSort({
        ...sort,
        order,
      });
    } else {
      onSort({
        name,
        order: 'asc',
      });
    }
  };

  const SortIcon = sort?.order === 'asc' ? ArrowUpward : ArrowDownward;

  return (
    <MaterialTableCell
      className={className}
      onClick={() => column.sortable && sortTable(column.field)}
    >
      <Box
        className={clsx({
          [classes.sortableCell]: column.sortable,
        })}
      >
        <Typography>{column.title}</Typography>
        {sort?.name === column.field && <SortIcon fontSize="small" />}
      </Box>
    </MaterialTableCell>
  );
};

export default TableHeaderCell;
