const moment = require('moment');

export const filterData = (arr: any[], filters: any) => {
  let filteredArray = [];

  filteredArray = arr.filter((el) => {
    let isValid = true;

    if(! ((filters.invalid && !el.valid) || (filters.positive && el.positive && el.valid) || (filters.negative && !el.positive && el.valid))) return false;
  
    for (let key in filters) {
      
      if(!isValid) return isValid;

      if ((key === 'camtechTests' || key === 'nonCamtechTests')) {
        if (filters.camtechTests && filters.nonCamtechTests) {
          isValid = true;
        } else if (!filters.camtechTests && !filters.nonCamtechTests) {
          isValid = false;
        } else if (!filters.camtechTests) {
          isValid = !!el.brand && el.brand !== 'Camtech';
        } else if (!filters.nonCamtechTests) {
          isValid = !!el.brand && el.brand !== 'Non Camtech';
        }
      }
      if ((key === 'antigen' || key === 'pcr')) {
        if (filters.antigen && filters.pcr) {
          isValid = true;
        } else if (!filters.antigen && !filters.pcr) {
          isValid = false;
        } else if (!filters.antigen) {
          isValid = el.type !== 'Antigen';
        } else if (!filters.prc) {
          isValid = el.type !== 'PRC';
        }
      }
      if (key === 'startDate') {
        if (
          moment(el.submitted_at.toDate()).isBefore(filters.startDate, 'day')
        ) {
          isValid = false;
        } else {
          isValid = true;
        }
      }
      if (key === 'endDate') {
        if (moment(el.submitted_at.toDate()).isAfter(filters.endDate, 'day')) {
          isValid = false;
        } else {
          isValid = true;
        }
      }
      if(key === "corporateId" && filters.corporateId) {
        isValid = el.employee_id.includes(filters.corporateId)
      }
    }

    return isValid;
  });

  return filteredArray;
};
