import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Link } from 'react-router-dom';

import { IMenuItem } from '../../types/Menu.types';

interface IProps {
  menuItem: IMenuItem;
  selected?: boolean;
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  link: {
    textDecoration: 'none',
  },
  root: {
    cursor: 'pointer',
    padding: spacing(2),
  },
  selected: {
    color: palette.primary.main,
    backgroundColor: palette.common.white,
  },
}));

const MenuItem = ({
  menuItem: { icon, title, link, selectedIcon },
  selected = false,
}: IProps) => {
  const classes = useStyles();

  return (
    <Link to={link} className={classes.link}>
      <Box
        className={clsx(classes.root, {
          [classes.selected]: selected,
        })}
      >
        {!selected ? (
          <img src={icon} alt={title} />
        ) : (
          <img src={selectedIcon} alt={title} />
        )}
        <Typography color={selected ? 'primary' : 'textPrimary'}>
          {title}
        </Typography>
      </Box>
    </Link>
  );
};

export default MenuItem;
