import { MouseEvent, useState } from 'react';
import { Box, Menu, Typography, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { IFilter } from '../../types/Filter.types';
import clsx from 'clsx';

const useStyles = makeStyles(({ spacing, palette }) => ({
  menuBox: {
    justifyContent: 'center',
  },
  label: {
    fontSize: 14,
    color: palette.text.primary,
    lineHeight: '14px',
  },
  menuCont: {
    marginTop: 4,
  },
  menu: {
    padding: 20,
    width: 244,
    height: 330,
  },
  topHeader: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 28,
    marginBottom: 20,
  },
  labelBox: {
    marginBottom: 10,
  },
  menuBoxBorder: {
    justifyContent: 'center',
    borderRadius: 4,
    border: '1px solid #9BC7DE',
    marginTop: 10,
  },
  datesCont: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
  },
  dateInsideCont: {
    marginRight: 5,
  },
  activeSection: {
    backgroundColor: palette.primary.main,

    '&:hover': {
      color: palette.primary.main,
    },
  },
  datePicker: {
    width: 119,
    '& .MuiInputBase-root': {
      padding: 0,
      '& .MuiButtonBase-root': {
        padding: 0,
        paddingLeft: 10,
      },
      '& .MuiInputAdornment-positionStart': {
        marginRight: 5,
      },
      '& .MuiInputBase-input': {
        paddingRight: 0,
        paddingLeft: 0,
        fontSize: 12,
      },
      '& .MuiOutlinedInput-notchedOutline': {},
    },
  },
}));

interface IProps {
  anchorEl: Element;
  handleClick: (event: MouseEvent<HTMLSpanElement>) => void;
  handleClose: () => void;
  handleFilterFieldValueChange: (val: Object) => void;
  filters: IFilter;
}

const FilterDatesPopup = ({
  anchorEl,
  handleClick,
  handleClose,
  handleFilterFieldValueChange,
  filters,
}: IProps) => {
  const open = Boolean(anchorEl);

  const classes = useStyles();
  const [currentSection, setCurrentSection] = useState<string>('');

  const dateChange = (param: string) => {
    const date = new Date();
    const secondDate = new Date();
    switch (param) {
      case 'today':
        handleFilterFieldValueChange({
          startDate: date,
          endDate: date,
        });
        setCurrentSection('today');
        break;

      case 'yest':
        date.setDate(date.getDate() - 1);
        handleFilterFieldValueChange({
          startDate: date,
          endDate: date,
        });
        setCurrentSection('yest');
        break;
      case 'thisWeek':
        secondDate.setDate(date.getDate() - 7);
        handleFilterFieldValueChange({
          startDate: secondDate,
          endDate: date,
        });
        setCurrentSection('thisWeek');
        break;

      case 'thisMonth':
        secondDate.setMonth(date.getMonth() - 1);
        handleFilterFieldValueChange({
          startDate: secondDate,
          endDate: date,
        });
        setCurrentSection('thisMonth');
        break;

      case 'past3month':
        secondDate.setMonth(date.getMonth() - 3);
        handleFilterFieldValueChange({
          startDate: secondDate,
          endDate: date,
        });
        setCurrentSection('past3month');
        break;

      default:
        break;
    }
  };

  return (
    <Box>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={classes.menuCont}
      >
        <Box className={classes.menu}>
          <Box className={classes.datesCont}>
            <Box className={classes.dateInsideCont}>
              <Box className={classes.labelBox}>
                <Typography className={classes.label}>From</Typography>
              </Box>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  clearable
                  value={filters.startDate}
                  onChange={(date) =>
                    handleFilterFieldValueChange({ startDate: date })
                  }
                  InputAdornmentProps={{ position: 'start' }}
                  placeholder="Date"
                  format="dd/MM/yyyy"
                  className={classes.datePicker}
                />
              </MuiPickersUtilsProvider>
            </Box>

            <Box>
              <Box className={classes.labelBox}>
                <Typography className={classes.label}>To</Typography>
              </Box>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  clearable
                  value={filters.endDate}
                  onChange={(date) =>
                    handleFilterFieldValueChange({ endDate: date })
                  }
                  InputAdornmentProps={{ position: 'start' }}
                  placeholder="Date"
                  format="dd/MM/yyyy"
                  className={classes.datePicker}
                />
              </MuiPickersUtilsProvider>
            </Box>
          </Box>

          <MenuItem
            onClick={() => dateChange('today')}
            key={'today'}
            className={clsx(classes.menuBoxBorder, {
              [classes.activeSection]: currentSection === 'today',
            })}
          >
            <Typography>Today</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => dateChange('yest')}
            key={'yest'}
            className={clsx(classes.menuBoxBorder, {
              [classes.activeSection]: currentSection === 'yest',
            })}
          >
            <Typography>Yesterday</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => dateChange('thisWeek')}
            key={'thisWeek'}
            className={clsx(classes.menuBoxBorder, {
              [classes.activeSection]: currentSection === 'thisWeek',
            })}
          >
            <Typography>This Week</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => dateChange('thisMonth')}
            key={'thisMonth'}
            className={clsx(classes.menuBoxBorder, {
              [classes.activeSection]: currentSection === 'thisMonth',
            })}
          >
            <Typography>This Month</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => dateChange('past3month')}
            key={'past3month'}
            className={clsx(classes.menuBoxBorder, {
              [classes.activeSection]: currentSection === 'past3month',
            })}
          >
            <Typography>Past 3 Month</Typography>
          </MenuItem>
        </Box>
      </Menu>
    </Box>
  );
};

export default FilterDatesPopup;
