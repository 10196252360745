import { MouseEvent, useContext } from 'react';
import { Box, Menu, Typography, MenuItem, Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import DatesIcon from '../../icons/datesIcon.png';
import { UserContext } from '../../App';

const useStyles = makeStyles(({ spacing, palette }) => ({
  menuBox: {
    justifyContent: 'center',
  },
  label: {
    fontSize: 20,
    color: palette.text.primary,
    fontWeight: 'bold',
    lineHeight: '28px',
  },
  menuCont: {
    marginTop: 4,
  },
  menu: {
    padding: 20,
    width: 244,
    height: 403,
  },
  topHeader: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 28,
    marginBottom: 20,
  },
  clearBtn: {
    color: palette.primary.main,
    fontSize: 14,
    lineHeight: '20px',
    textDecoration: 'underline',
    fontFamily: 'Inter',
    fontWeight: 400,
  },
  labelBox: {
    marginTop: 20,
    marginBottom: 10,
  },
  img: {
    marginRight: 8,
  },
  menuBoxDate: {
    justifyContent: 'center',
    borderRadius: 4,
    border: '1px solid #9BC7DE',
  },
}));

interface IProps {
  anchorEl: Element;
  handleClick: (event: MouseEvent<HTMLSpanElement>) => void;
  handleClose: () => void;
  openDatesPopup: () => void;
  handleFilterFieldValueChange: (val: Object) => void;
  clearSelectedFilters: () => void;
  filters: any;
}

const FilterPopup = ({
  anchorEl,
  handleClick,
  handleClose,
  openDatesPopup,
  handleFilterFieldValueChange,
  clearSelectedFilters,
  filters,
}: IProps) => {
  const open = Boolean(anchorEl);

  const classes = useStyles();
  const user = useContext(UserContext);
  let timeout = null

  return (
    <Box>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={classes.menuCont}
      >
        <Box className={classes.menu}>
          <Box className={classes.topHeader}>
            <Typography className={classes.label}>Filters</Typography>
            <Box onClick={clearSelectedFilters} className={classes.clearBtn}>
              Clear all
            </Box>
          </Box>
          <MenuItem
            onClick={() => openDatesPopup()}
            key={'csv'}
            className={classes.menuBoxDate}
          >
            <img src={DatesIcon} alt="" className={classes.img} />
            <Typography>Date</Typography>
          </MenuItem>
          {user.corporation === 'ALL' && filters.hasOwnProperty('corporateId') && (
            <>
              <Box className={classes.labelBox}>
                <Typography className={classes.label}>Group ID</Typography>
              </Box>
              <Input
                onChange={(e) => {
                  clearTimeout(timeout)
                  timeout = setTimeout(function() {
                    handleFilterFieldValueChange({
                      corporateId: e.target.value,
                    })
                  }, 300)
                }}
                name="Group ID"
                color="primary"
                placeholder="Group ID"
              />
            </>
          )}
          <Box className={classes.labelBox}>
            <Typography className={classes.label}>Status</Typography>
          </Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.negative}
                  onChange={() =>
                    handleFilterFieldValueChange({
                      negative: !filters.negative,
                    })
                  }
                  name="negative"
                  color="primary"
                />
              }
              label="Negative"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.positive}
                  onChange={() =>
                    handleFilterFieldValueChange({
                      positive: !filters.positive,
                    })
                  }
                  name="positive"
                  color="primary"
                />
              }
              label="Positive"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.invalid}
                  onChange={() =>
                    handleFilterFieldValueChange({ invalid: !filters.invalid })
                  }
                  name="invalid"
                  color="primary"
                />
              }
              label="Invalid"
            />
          </FormGroup>
          <Box className={classes.labelBox}>
            <Typography className={classes.label}>Type</Typography>
          </Box>
          <FormGroup>
            {filters.hasOwnProperty('camtechTests') && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.camtechTests}
                    onChange={() =>
                      handleFilterFieldValueChange({
                        camtechTests: !filters.camtechTests,
                      })
                    }
                    name="Camtech Tests"
                    color="primary"
                  />
                }
                label="Camtech Tests"
              />
            )}
            {filters.hasOwnProperty('nonCamtechTests') && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.nonCamtechTests}
                    onChange={() =>
                      handleFilterFieldValueChange({
                        nonCamtechTests: !filters.nonCamtechTests,
                      })
                    }
                    name="Non-Camtech Tests"
                    color="primary"
                  />
                }
                label="Non-Camtech Tests"
              />
            )}
            {filters.hasOwnProperty('antigen') && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.antigen}
                    onChange={() =>
                      handleFilterFieldValueChange({
                        antigen: !filters.antigen,
                      })
                    }
                    name="antigen"
                    color="primary"
                  />
                }
                label="Antigen"
              />
            )}
            {filters.hasOwnProperty('pcr') && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.pcr}
                    onChange={() =>
                      handleFilterFieldValueChange({
                        pcr: !filters.pcr,
                      })
                    }
                    name="prc"
                    color="primary"
                  />
                }
                label="PCR"
              />
            )}
          </FormGroup>
        </Box>
      </Menu>
    </Box>
  );
};

export default FilterPopup;
