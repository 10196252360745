import { MoreMenuItem } from '../types/MoreMenu.types';
import About from '../pages/More/components/About';
import Contact from '../pages/More/components/Contact';
import Reorder from '../pages/More/components/Reorder';
import BookLabTests from '../pages/More/components/BookLabTest';
import Terms from '../pages/More/components/Terms';
import Tutorial from '../pages/More/components/Tutorial';

export const moreMenuItems: MoreMenuItem[] = [
  {
	section: 'reorder',
	title: 'Reorder Test Kits',
	key: 'reorder',
	content: <Reorder />,
  },
  {
	section: 'bookLabTest',
	title: 'Book A Lab Test',
	key: 'bookLabTest',
	content: <BookLabTests />,
  },
  {
	section: 'tutorial',
	title: 'Tutorial',
	key: 'tutorial',
	content: <Tutorial />,
  },
  {
	section: 'contact',
	title: 'Contact Us',
	key: 'contact',
	content: <Contact />,
  },
  {
    section: 'about',
    title: 'About Camtech',
    key: 'about',
    content: <About />,
  },
  {
    section: 'terms',
    title: 'Terms and Conditions',
    key: 'terms',
    content: <Terms />,
  },
];
