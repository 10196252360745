import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { steps } from './const';

import step15a from '../../../../icons/tutorial/step-15-a.png';
import step15b from '../../../../icons/tutorial/step-15-b.png';
import step15c from '../../../../icons/tutorial/step-15-c.png';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(1),
    flexGrow: 1,
  },
  title: {
    fontWeight: 'bold',
    marginTop: spacing(2),
  },
  image: {
    height: 150,
    marginTop: spacing(1),
  },
}));

const Tutorial = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography variant="h5" color="textPrimary">
        COVID-19 Antigen Rapid Self-Test User Instruction
      </Typography>

      <Typography variant="h6" className={classes.title} color="primary">
        Materials Provided
      </Typography>

      <Typography color="textPrimary">
        2 Test Pouch containing Test Card
      </Typography>
      <Typography color="textPrimary">
        2 Antigen Extraction Tubes filled with buffer
      </Typography>
      <Typography color="textPrimary">2 Sterile Swabs</Typography>
      <Typography color="textPrimary">2 Ziplock Bag</Typography>
      <Typography color="textPrimary">1 Instruction for use</Typography>

      {steps.map((step, index) => (
        <>
          <Typography variant="h6" className={classes.title} color="primary">
            Step {index + 1}
          </Typography>
          <Typography color="textPrimary">{step.content}</Typography>
          {step.image && (
            <div>
              <img src={step.image} alt="" className={classes.image} />
            </div>
          )}
        </>
      ))}

      <Typography variant="h6" className={classes.title} color="primary">
        Step {steps.length + 1}
      </Typography>
      <Typography color="textPrimary">
        <b>Check Your Results</b>
        <br />
        There are three types of results possible: Positive Result, Negative
        Result and Invalid Result.
        <br />
        <br />
        <b>a.</b> Positive result: if both the quality
		control line C and the detection line T
		appear, novel coronavirus antigen has
		been detected and the result is positive
		for antigen.
        <div>
          <img src={step15a} alt="" className={classes.image} />
        </div>
        <b>b.</b> Negative result: if there is only a
		quality control line C and the detection
		line is colorless, indicating that the
		novel coronavirus antigen has not been
		detected and the result is negative.
        <div>
          <img src={step15b} alt="" className={classes.image} />
        </div>
        <b>c.</b> Invalid result: if the quality control
		line C is not observed, the test is
		invalid regardless of whether or not
		there is detection line T (as shown
		in the figure). Repeat the test.
        <div>
          <img src={step15c} alt="" className={classes.image} />
        </div>
      </Typography>
    </Box>
  );
};

export default Tutorial;
