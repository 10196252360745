import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dropdown from '../Dropdown';
import { logout } from '../../api';
import { IDropdownOption } from '../Dropdown/Dropdown';
import { useContext } from 'react';
import { UserContext } from '../../App';
import { firebaseConfig } from '../../const/firebase.config';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  logo: {
    width: 175,
  },
  red: {
    color: '#FF0000'
  }
}));

const Header = () => {
  const classes = useStyles();

  const user = useContext(UserContext);

  const options: IDropdownOption[] = [
    {
      label: 'Logout',
      onClick: logout,
      button: true,
    },
  ];

  return (
    <Box className={classes.root}>
      <img src="/logo.png" alt="Camtech" className={classes.logo} />

      { 
        firebaseConfig.projectId === "test-camtech-app" && 
        <Typography className={classes.red}>
          Test environment
        </Typography>
      }
      <Dropdown label={user?.email} options={options} />
    </Box>
  );
};

export default Header;
