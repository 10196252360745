import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { UserContext } from '../../App';
import StatsCards, { Stats } from './StatsCards';
import Graph from './Graph';
import { fetchGroup } from '../Groups/api';
import { getGroupItemLink, getTestsListLink } from '../../api/const';
import { fetchData } from '../../components/Table/api';
import { calculateTestedType } from './helpers';
import { filterData } from '../../functions/filter';
import { ITest } from '../../types/Test.types';
import { IFilter } from '../../types/Filter.types';

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  root: {
    height: '100%',
    color: palette.text.primary,
  },
  corporateIds: {
    textAlign: 'right',
  },
  title: {
    paddingTop: 5,
    marginBottom: 15,
  },
  subtitle: {
    color: '#BDBDBD',
  },
  header: {
    maxHeight: 165,
    marginBottom: spacing(6),
    marginTop: spacing(2),
  },
  statsContainer: {
    flexDirection: 'column',
    marginBottom: spacing(8),
    [breakpoints.up('sm')]: {
      maxHeight: 190,
      flexDirection: 'row',
    },
  },
  graphContainer: {
    maxHeight: 330,
    marginBottom: spacing(6),
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const user = useContext(UserContext);

  const initialFilter = {
    startDate: new Date(Date.now() - 12096e5),
    positive: true,
    negative: true,
    invalid: true,
    antigen: true,
    pcr: true,
    endDate: new Date(),
  };

  const [filters, setFilters] = useState<IFilter | any>(initialFilter);
  const [filteredData, setFilteredData] = useState([]);

  const [stats, setStats] = useState<Stats>({
    testedPositive: 0,
    testedNegative: 0,
    testedInvalid: 0,
    allTests: 0,
    userId: '',
    medicalId: '',
  });

  const isAdmin = user.corporation === 'ALL';

  const calculateAllTests = (data: any[]) => {
    return data
      .sort(
        (a: ITest, b: ITest) => b.submitted_at.seconds - a.submitted_at.seconds
      )
      .filter(
        (test: ITest) =>
          test ===
          data.find(
            (latestTest: ITest) =>
              test.patient.email === latestTest.patient.email
          )
      );
  };

  const fetchRows = async () => {
    const companies = await fetchData('corporations');
    if (companies.length) {
      let tests = [];

      companies.forEach(async (comp, index) => {
        const fetchedRows = await fetchData(`corporations/${comp.id}/tests`);

        tests = [
          ...tests,
          ...fetchedRows.map((el) => {
            return { ...el, corporate_id: comp.id };
          }),
        ];

        if (index === companies.length - 1) {
          const allTests = calculateAllTests(filterData(tests, filters));

          setFilteredData(allTests);

          setStats({
            ...stats,
            allTests: allTests.length,
            testedPositive: calculateTestedType(allTests, 'positive'),
            testedNegative: calculateTestedType(allTests, 'negative'),
            testedInvalid: calculateTestedType(allTests, 'invalid'),
          });
        }
      });
    }
  };

  useEffect(() => {
    if (isAdmin) fetchRows();
    else {
      !isAdmin &&
        fetchGroup(getGroupItemLink(user.corporation)).then((e) => {
          return fetchData(getTestsListLink(user.corporation)).then(
            (testsList) => {
              const filteredData = filterData(testsList, filters);

              const allTests = calculateAllTests(filteredData);

              setFilteredData(allTests);

              setStats({
                userId: e.employee_id,
                medicalId: e.medical_professional_id,
                allTests: allTests.length,
                testedPositive: calculateTestedType(allTests, 'positive'),
                testedNegative: calculateTestedType(allTests, 'negative'),
                testedInvalid: calculateTestedType(allTests, 'invalid'),
              });
            }
          );
        });
    }
  }, [user, filters]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box className={classes.root}>
      <Grid container direction="column" spacing={1}>
        <Grid className={classes.header} item xs container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="h3" className={classes.title}>
              <b>Group Dashboard</b>
            </Typography>
          </Grid>
          <Grid item xs container direction="column" spacing={2}>
            {!isAdmin && (
              <Grid item xs className={classes.corporateIds}>
                <Typography gutterBottom variant="subtitle1">
                  Group ID: {stats ? stats.userId : ''}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Group ID for MP : {stats ? stats.medicalId : ''}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          className={classes.statsContainer}
          item
          xs
          sm
          container
          spacing={3}
        >
          {stats !== null && <StatsCards filters={filters} stats={stats} />}
        </Grid>
        <Grid
          item
          xs
          container
          className={classes.graphContainer}
          justifyContent="center"
          alignItems="center"
        >
          <Graph
            filteredData={filteredData}
            filters={filters}
            setFilters={setFilters}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
