import step3 from '../../../../icons/tutorial/step-3.png';
import step4 from '../../../../icons/tutorial/step-4.png';
import step5 from '../../../../icons/tutorial/step-5.png';
import step6 from '../../../../icons/tutorial/step-6.png';
import step7 from '../../../../icons/tutorial/step-7.png';
import step8 from '../../../../icons/tutorial/step-8.png';
import step9 from '../../../../icons/tutorial/step-9.png';
import step10 from '../../../../icons/tutorial/step-10.png';
import step11 from '../../../../icons/tutorial/step-11.png';
import step12 from '../../../../icons/tutorial/step-12.png';
import step13 from '../../../../icons/tutorial/step-13.png';
import step14 from '../../../../icons/tutorial/step-14.png';

type TStep = {
  content: string | JSX.Element;
  image?: string;
};
export const steps: TStep[] = [
  {
    content:
      'Before you start testing, wash your ' +
	  'hands or use hand sanitizer. Make sure ' +
	  'your hands are dry before starting.',
  },
  {
    content: 'Take out the test pouch and other provided materials and ' +
	  'lay them on the table to prepare for use.' +
	  'Perform the test at room temperature and 30%-50% humidity' +
	  'for best results.',
  },
  {
    content: 'Remove cap from one of the tubes and discard the cap.',
    image: step3,
  },
  {
    content: 'Place tube in the tube holder. Use of gloves is recommended.',
    image: step4,
  },
  {
    content: 'Remove the swab from its wrapper, being ' +
	  'careful not to touch the soft end, which is ' +
	  'the absorbent tip.',
    image: step5,
  },
  {
    content: (
      <>
        <b>Swab the Nostrils</b>
        <br />
        <b>a.</b> Insert the entire absorbent tip of the swab into
		the nostril, but do not insert the swab more
		than ¾ of an inch (1.5 cm) into the nose.
        <br />
        <b>b.</b> Slowly rotate the swab in a circular path against
		the inside the nostril at least 4 times for a total of
		15 seconds. Be sure to collect any nasal secretion
		that may be present on the swab.
        <br />
        <b>c.</b> Gently remove the swab.
        <br />
        <b>d.</b> Using the same swab, repeat steps a to b in the other nostril.
        <br />
      </>
    ),
    image: step6,
  },
  {
    content: (
      <>
        <b>Place Swab in the Tube</b>
        <br />
        <b>a.</b> Immediately place the swab into the liquid inside the
		tube, and ensure it is touching the bottom of the tube.
        <br />
        <b>b.</b> Stir 8-10 times.
        <br />
        <b>c.</b> Leave the swab in the solution for 1 minute.
        <br />
      </>
    ),
    image: step7,
  },
  {
    content:
      'After one minute, Remove the swab while squeezing wall of the tube against the ' +
	  'swab while rotating the swab, to extract the nasal secretions from the swab',
    image: step8,
  },
  {
    content: 'Dispose the swab in the disposable ziplock bag.',
    image: step9,
  },
  {
    content: 'Close up the tube firmly using attached tip.',
    image: step10,
  },
  {
    content: 'Mix thoroughly by flicking the bottom of the tube.',
    image: step11,
  },
  {
    content: 'Take out the test card from the test pouch.',
    image: step12,
  },
  {
    content:
      'Hold the tube vertically and squeeze 4 drops of the solution onto the sample well(s) ' +
	  'of the test card by squeezing the wall of the test tube.',
    image: step13,
  },
  {
    content: 'Wait for 15 minutes after adding the sample solution onto the well of the test card and ' +
	  'interpret the results. Do not evaluate the kit after more than 20 minutes.',
    image: step14,
  },
];
