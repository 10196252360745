import { createTheme } from '@material-ui/core/styles';

const inputSize = 48;

const theme = createTheme({
  typography: {
    fontFamily: ['"Inter"', 'sans-serif'].join(','),
    fontSize: 14,
  },
  palette: {
    primary: {
      main: '#64A2C8',
      contrastText: '#fff',
    },
    background: {
      default: '#E5F1F6',
    },
    text: {
      primary: '#616161',
    },
  },
  props: {
    MuiCard: {
      style: {
        boxShadow: '2px 5px 15px rgba(71, 104, 122, 0.15)',
      },
    },
    MuiInputBase: {
      style: { height: inputSize },
    },
    MuiButtonBase: {
      disableRipple: true,
      style: { textTransform: 'none', fontWeight: 400 },
    },
    MuiButton: {
      disableElevation: true,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 650,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default theme;
