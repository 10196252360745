import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import React from 'react';
import cancelIcon from '../../icons/successIcon.png';
import { Button, Box, Typography } from '@material-ui/core';

const useStyles = makeStyles(({ spacing, palette }) => ({
  button: {
    height: 48,
    backgroundColor: palette.primary.main,
    color: 'white',
    fontSize: 16,
    lineHeight: '24px',
    width: 180,
    marginTop: 30,
    margin: '0 auto',
  },
  paper: {
    position: 'absolute',
    width: 471,
    backgroundColor: 'white',
    height: 149,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    justifyContent: 'center',
    padding: '60px 70px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    color: palette.text.primary,
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: '32px',
    marginBottom: 15,
  },
  img: {
    width: 60,
    height: 60,
    marginRight: 30,
  },

  fieldLable: {
    color: palette.primary.main,
    paddingTop: 12,
  },
  text: {
    color: palette.text.primary,
    fontSize: 16,
    lineHeight: '24px',
  },
}));

function getModalStyle() {
  const top = '50%';
  const right = 0;
  return {
    top: `${top}%`,
    right: `${right}%`,
  };
}

interface IProps {
  onClose: () => void;
  reportsIsGeneratedPopupVisible: boolean;
  pdfReportLink: string;
  clearPdfReportsLink: () => void;
}

const ReportIsGeneratedPopup = ({
  onClose,
  reportsIsGeneratedPopupVisible,
  pdfReportLink,
  clearPdfReportsLink,
}: IProps) => {
  const classes = useStyles();

  const [modalStyle] = React.useState(getModalStyle);

  const onClick = () => {
    let save = document.createElement('a');
    save.href = pdfReportLink;
    save.download = 'Your file name here';
    save.target = '_blank';
    document.body.appendChild(save);
    save.click();
    document.body.removeChild(save);
    closePopup();
  };

  const closePopup = () => {
    clearPdfReportsLink();
    onClose();
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={reportsIsGeneratedPopupVisible}
      onClose={closePopup}
    >
      <Box style={modalStyle} className={classes.paper}>
        <Box className={classes.header}>
          <Box>
            <img src={cancelIcon} className={classes.img} alt="close" />
          </Box>
          <Box>
            <Typography className={classes.title}>
              Your report has been generated.
            </Typography>
            <Typography className={classes.text}>
              Download your report.
            </Typography>
          </Box>
        </Box>

        <Button
          variant="contained"
          type="submit"
          className={classes.button}
          size="large"
          onClick={onClick}
        >
          Download Report
        </Button>
      </Box>
    </Modal>
  );
};

export default ReportIsGeneratedPopup;
