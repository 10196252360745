import { ITableColumn } from '../../types/Table.types';
import { IGroup } from '../../types/Group.types';
import { formatDateTime } from '../../utils';

export const testsColumns: ITableColumn[] = [
  {
    title: 'Group Name',
    field: 'name',
    sortable: true,
  },
  {
    title: 'Group ID',
    field: 'employee_id',
    sortable: true,
  },
  {
    title: 'Medical ID',
    field: 'medical_professional_id',
    sortable: true,
  },
  {
    title: 'Country',
    field: 'location',
    sortable: true,
  },
  {
    title: 'Created Date',
    field: 'created_at',
    sortable: true,
    changeValue: (row: IGroup) => formatDateTime(row.created_at),
  },
  {
    title: 'Status',
    field: 'status',
    sortable: true,
  },
];

export const exportTestsFields = {
  firstName: 'Group Name',
  lastName: 'Group ID',
  email: 'Group ID for MP',
  type: 'Created Date',
  result: 'Status',
};

export const getTestExportRow = (group: IGroup) => ({
  name: `"${group.name}"`,
  id: `"${group.id}"`,
  mpId: `"${group.mpId}"`,
  status: `"${group.status}"`,
  date: `"${formatDateTime(group.created_at)}"`,
});
