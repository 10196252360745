import { doc, getDoc } from 'firebase/firestore';
import { IUser } from '../types/User.types';
import { firestore } from '../utils/firebase';
import { auth } from '../utils/firebase'
import { getGroupLink, getUserLink } from './const';

const alertAndSignOut = (message: string): Promise<IUser | null> => {
  alert(message)
  auth.signOut()
  return null
}

export const fetchUser = async (id: string): Promise<IUser | null> => {

  try {
    const userDocSnap = await getDoc(doc(firestore, getUserLink(id)));

    if (!userDocSnap.exists()) {
      return alertAndSignOut("Internal error, please contact a system administrator.")
    }

    const userData = userDocSnap.data()
      
    const isAdmin = userData.user != null;

    if(!isAdmin) {
      return alertAndSignOut("Your account is not authorised to login to the Admin console of Camtech Health, please use the mobile app instead.")
    }

    if(userData.corporation !== "ALL") {

      const groupDocSnap = await getDoc(doc(firestore, getGroupLink(userData.corporation)));
    
      if(!groupDocSnap.exists())  {
        return alertAndSignOut("Internal error, please contact a system administrator.")
      }

      if(groupDocSnap.data().status !== "Active") {
        return alertAndSignOut("Your account is not authorised to login to the Admin console of Camtech Health, please use the mobile app instead.")
      } 
    }
    
    return {
      active: userData.active,
      age: userData.age,
      contactNumber: userData.contactNumber,
      corporateId: userData.corporateId,
      corporation: userData.corporation,
      created: userData.created,
      email: userData.email,
      firstName: userData.firstName,
      gender: userData.gender,
      jobTitle: userData.jobTitle,
      lastName: userData.lastName,
      status: userData.status,
      type: userData.type
    }
      
  } catch(e) {
    return alertAndSignOut("Your account has not been authorised to use Camtech Health yet.")
  } 
}