import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../const/firebase.config';

import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

initializeApp(firebaseConfig);

export const auth = getAuth();
export const firestore = getFirestore();

