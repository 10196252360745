import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../utils/firebase';
import { IGroup } from '../../types/Group.types';
import firebase from 'firebase/compat';
import {firebaseConfig} from '../../const/firebase.config';


export const fetchGroup = async (link: string): Promise<IGroup| null> => {
  const docSnap = await getDoc(doc(firestore, link));
  
  if (docSnap.exists()) {
	return docSnap.data() as IGroup;
  }
  
  return null;
};

firebase.initializeApp(firebaseConfig);

export const changeGroupStatus = async (id: string, status: string): Promise<any | null> => {
  try {
    await firebase.firestore().collection("corporations").doc(id).update({
      ...doc,
      status
    });
  } catch (e) {
    console.log(e);
  }
};

export const deleteGroup = async (id: string): Promise<any | null> => {
  try {
    await firebase.firestore().collection("corporations").doc(id).delete();
  } catch (e) {
    console.log(e);
  }
};

export const getTestsQuantity = async (id: string) => {
  let size = 0;
  try {
    await firebase.firestore().collection("corporations").doc(id).collection('tests').get().then(snap => {
      size = snap.size
    });
	  return size;
  } catch (e) {
	  console.log(e);
  }
}

export const downloadTestKits = async (id: string) => {

  let positive = []
  let negative = []
  let invalid = []
  
  try {
    await firebase.firestore().collection("corporations").doc(id).collection('tests').get().then(snap => {
      snap.docs.forEach(t => {
        let data = t.data()
        if(!data.valid) invalid.push(data.photo)
        else if(data.positive) positive.push(data.photo)
        else if(!data.positive) negative.push(data.photo)
      })
    });

    return {
      positive,
      negative,
      invalid
    }

  } catch (e) {
	  console.log(e);
  }
}

