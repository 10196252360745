import React, { useEffect, useState } from 'react';
import { CartesianGrid, Line, XAxis, YAxis, LineChart } from 'recharts';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import useWindowSize from '../../utils/useWindowSize';
import {
  CustomGreenCheckboxIcon,
  CustomGreenCheckedIcon,
  CustomRedCheckboxIcon,
  CustomRedCheckedIcon,
} from '../../icons/CustomCheckoxIcon';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { IFilter } from '../../types/Filter.types';
import { ITableColumn } from '../../types/Table.types';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    borderRadius: 20,
    borderWidth: 1,
    borderColor: palette.primary.main,
    borderStyle: 'solid',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.text.primary,
  },
  header: {
    paddingInline: spacing(4),
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  results: {
    paddingRight: 20,
  },
  datePicker: {
    maxWidth: 120,
    cursor: 'pointer',
  },
  datePickerSeparator: {
    position: 'relative',
    top: 15,
    right: 15,
  },
  datePickerLabel: {
    position: 'absolute',
    backgroundColor: '#fff',
    zIndex: 5,
    width: 300,
    height: 30,
    borderWidth: 1,
    borderColor: palette.primary.main,
    borderStyle: 'solid',
    borderRadius: 10,
  },
}));

interface IGraphProps {
  filters: IFilter;
  setFilters: (val: IFilter) => void;
  filteredData: ITableColumn[];
}

const Graph: React.FC<IGraphProps> = (props) => {
  const classes = useStyles();
  const windowSizes = useWindowSize();
  const { filters, setFilters, filteredData } = props;

  const [graphData, setGraphData] = useState([]);
  const [resultTypes, setResultTypes] = useState({
    positive: true,
    negative: true,
  });

  const handleStartDateChange = (val: Date) => {
    setFilters({ ...filters, startDate: val.toISOString() });
  };
  const handleEndDateChange = (val: Date) => {
    setFilters({ ...filters, endDate: val.toISOString() });
  };

  useEffect(() => {
    renderGraphListData(filteredData);
  }, [filters, filteredData]); //eslint-disable-line react-hooks/exhaustive-deps

  const renderGraphListData = (filteredData: ITableColumn[] | any) => {
    
    var dateIterator = moment(filters.startDate)
    var allDates = []
    
    while (dateIterator.isSameOrBefore(moment(filters.endDate))) {
      allDates.push(dateIterator.format('DD-MM').toString());
      dateIterator.add(1, 'days');
    }

    const datesList = filteredData.map((item: any) => {
      return moment(item.submitted_at.toDate()).endOf('day').toISOString();
    });

    const uniqueDates = [...new Set(datesList)].sort(
      (a, b) => moment(a).dayOfYear() - moment(b).dayOfYear()
    );
    
    const graphListData = uniqueDates.map((date: string) => {
      let positiveRes = filteredData.filter(
        (i) =>
          moment(i.submitted_at.toDate()).endOf('day').toISOString() === date &&
          i.positive
      );
      let negativeRes = filteredData.filter(
        (i) =>
          moment(i.submitted_at.toDate()).endOf('day').toISOString() === date &&
          !i.positive &&
          i.valid
      );
      return {
        name: moment(date).format('DD-MM').toString(),
        positiveResults: positiveRes.length,
        negativeResults: negativeRes.length,
      };
    });

    setGraphData(allDates.map((d) => {
      console.log(d)
      const dateWithData = graphListData.find((gld) => gld.name === d)
      return dateWithData ?? {
        name: d,
        positiveResults: 0,
        negativeResults: 0
      }
    }));
  };

  return (
    <Box className={classes.root}>
      <Grid
        className={classes.header}
        justifyContent="space-evenly"
        alignItems="center"
        container
      >
        <Grid item xs>
          <Typography variant="h5">
            <b>Users</b>
          </Typography>
        </Grid>
        <Grid direction="row" item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              autoOk
              variant="inline"
              clearable
              value={filters.startDate}
              className={classes.datePicker}
              onChange={(date) => handleStartDateChange(date)}
              InputProps={{ disableUnderline: true }}
              placeholder="Date"
              format="dd/MM/yyyy"
            />
            <b className={classes.datePickerSeparator}>-</b>
            <DatePicker
              autoOk
              variant="inline"
              clearable
              className={classes.datePicker}
              value={filters.endDate}
              onChange={(date) => handleEndDateChange(date)}
              InputProps={{ disableUnderline: true }}
              placeholder="Date"
              format="dd/MM/yyyy"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid direction="row" item xs>
          <FormGroup className={classes.formGroup}>
            <Typography className={classes.results} variant="h6">
              Results
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={resultTypes.negative}
                  onChange={() =>
                    setResultTypes({
                      ...resultTypes,
                      negative: !resultTypes.negative,
                    })
                  }
                  checkedIcon={<CustomGreenCheckedIcon />}
                  icon={<CustomGreenCheckboxIcon />}
                />
              }
              label="Negative"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={resultTypes.positive}
                  onChange={() =>
                    setResultTypes({
                      ...resultTypes,
                      positive: !resultTypes.positive,
                    })
                  }
                  checkedIcon={<CustomRedCheckedIcon />}
                  icon={<CustomRedCheckboxIcon />}
                />
              }
              label="Positive"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Box>
        <LineChart
          width={windowSizes.width - 175}
          height={250}
          data={graphData}
          margin={{ top: 30, right: 80, left: 0, bottom: 0 }}
        >
          <CartesianGrid horizontal stroke="#EFF3F9" />
          <XAxis dataKey="name" tick={{ fill: '#616161' }} />
          <YAxis tick={{ fill: '#616161' }} />
          {resultTypes.positive && (
            <Line
              type="monotone"
              dataKey="positiveResults"
              stroke="#B71C1C"
              strokeWidth="1"
            />
          )}
          {resultTypes.negative && (
            <Line
              type="monotone"
              dataKey="negativeResults"
              stroke="#AED581"
              strokeWidth="1"
            />
          )}
        </LineChart>
      </Box>
    </Box>
  );
};

export default Graph;
