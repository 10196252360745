import {Box, Typography, Button, Link, ListItemText, List, ListItem} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import reorderBackground from '../../../../icons/bookLabTestBackground.png';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
	gap: spacing(1),
	flexGrow: 1,
  },
  image: {
	width: 446,
	height: 300,
	marginBottom: spacing(9),
  },
  button: {
	padding: `${spacing(1.5)}px ${spacing(10)}px`,
	marginTop: spacing(2),
  },
  listItem: {
    padding: 0,
  },
  listItemText: {
	color: palette.text.primary,
  }
}));

const BookLabTest = () => {
  const classes = useStyles();
  
  
  const options = [
    'Neutralising Antibody Test',
	'PCR Test',
	'Pre-departure PCR Test'
  ];
  
  return (
	<Box className={classes.root}>
	  <img src={reorderBackground} alt="img" className={classes.image} />
	  
	  <Typography variant="h5" color="textPrimary">
		Book a Lab Test
	  </Typography>
	  
	  <Typography color="textPrimary">
		Please contact us at{' '}
		<Typography component="span">
		  <Link
			href="mailto:sales@camtechlab.org"
			target="_blank"
			rel="nofollow noopener"
		  >
			sales@camtech.org
		  </Link>
		</Typography>{' '}
		to book our:
	  </Typography>
	  
	<List>
	  {options.map((item: string) => (
		<ListItem key={item} className={classes.listItem}>
		  <ListItemText className={classes.listItemText} primary={`\u2022 ${item}`} />
		</ListItem>
	  ))}
	</List>
	
	  <Button
		color="primary"
		variant="contained"
		className={classes.button}
		href="mailto:sales@camtechlab.org"
		target="_blank"
		rel="nofollow noopener"
	  >
		Book a Test
	  </Button>
	</Box>
  );
};

export default BookLabTest;
