import { ITableRow } from '../../types/Table.types';
import { ITest } from '../../types/Test.types';
import { filterData } from '../../functions/filter';

export const calculateNumberOfUsers = (
  testsArray: Array<ITableRow | ITest>
) => {

  if (!testsArray.length) return 0;
  
  const emailsArr = testsArray.map((testItem: ITest) => testItem.patient.email);
  
  return [...new Set(emailsArr)].length;
};

export const calculateTestedType = (
  testsArray: Array<ITableRow | ITest>,
  type: string
) => {
  
  if (!testsArray.length) return 0;
    
  let filterObj = {
    positive: false,
    negative: false,
    invalid: false,
    antigen: true,
    pcr: true,
  };
  
  filterObj[type] = true;
    
  const filteredArr = [];
  
  filterData(testsArray, filterObj).forEach((item: ITest) => {
      if (! filteredArr.some((el) => el.patient.email === item.patient.email)) {
        filteredArr.push(item);
      }
    });

  return [...new Set(filteredArr)].length;
};
