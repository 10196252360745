import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import React from 'react';
import cancelIcon from '../../icons/cancelIcon.png';
import { Button, Box, Typography } from '@material-ui/core';

const useStyles = makeStyles(({ spacing, palette }) => ({
  button: {
    height: 48,
    backgroundColor: palette.primary.main,
    color: 'white',
    fontSize: 16,
    lineHeight: '24px',
    width: 180,
    marginTop: 30,
    margin: '0 auto',
    borderRadius: 10,
  },
  paper: {
    position: 'absolute',
    width: 457,
    backgroundColor: 'white',
    height: 149,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    justifyContent: 'center',
    padding: '60px 70px',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    color: palette.text.primary,
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: '32px',
    marginBottom: 15,
  },
  img: {
    width: 60,
    height: 60,
    marginRight: 30,
  },

  fieldLable: {
    color: palette.primary.main,
    paddingTop: 12,
  },
  text: {
    color: palette.text.primary,
    fontSize: 16,
    lineHeight: '24px',
  },
}));

function getModalStyle() {
  const top = '50%';
  const right = 0;
  return {
    top: `${top}%`,
    right: `${right}%`,
  };
}

interface IProps {
  onClose: () => void;
  maxNumberOfReportsPopupVisible: boolean;
}

const ReportsLimitModal = ({
  onClose,
  maxNumberOfReportsPopupVisible,
}: IProps) => {
  const classes = useStyles();

  const [modalStyle] = React.useState(getModalStyle);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={maxNumberOfReportsPopupVisible}
      onClose={onClose}
    >
      <Box style={modalStyle} className={classes.paper}>
        <Box className={classes.header}>
          <Box>
            <img src={cancelIcon} className={classes.img} alt="close" />
          </Box>
          <Box>
            <Typography className={classes.title}>
              The report cannot be exported.
            </Typography>
            <Typography className={classes.text}>
              There are more than 10,000 rows.
            </Typography>
          </Box>
        </Box>

        <Button
          variant="contained"
          type="submit"
          className={classes.button}
          size="large"
          onClick={onClose}
        >
          Ok
        </Button>
      </Box>
    </Modal>
  );
};

export default ReportsLimitModal;
