import {
  getDocs,
  query,
  collection,
  orderBy,
  OrderByDirection,
} from 'firebase/firestore';
import { ISort, ITableRow } from '../../types/Table.types';
import { firestore } from '../../utils/firebase';

export const sortBy = (field, order = 'asc') => {
  const key = (x) => x[field];
  let reverse = order === 'asc' ? 1 : -1;
  return function (a, b) {
    return (
      (a = key(a)),
      (b = key(b)),
      reverse * (((a > b) as any) - ((b > a) as any))
    );
  };
};

export const fetchData = async (
  link: string,
  sort?: ISort
): Promise<ITableRow[]> => {
  const results: ITableRow[] = [];

  // if there is sorting then should be applied. Can't pass nulls to orderBy to avoid sorting at all
  const q = sort
    ? query(
        collection(firestore, link),
        orderBy(sort.name, sort.order as OrderByDirection)
      )
    : query(collection(firestore, link));

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    const test = doc.data();

    results.push({
      ...test,
      id: doc.id,
    });
  });

  return results;
};
