import { useContext, useState, useEffect, useCallback } from 'react';
import { Box, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Table } from '../../components/Table';
import { ITableRow } from '../../types/Table.types';
import TestDetails from '../Tests/TestDetails';
import { UserContext } from '../../App';
import { ITest } from '../../types/Test.types';
import {
  exportTestsFields,
  getTestExportRow,
  testsColumns,
} from '../Tests/const';
import TestsHeader from '../Tests/TestsHeader';
import { getTestsListLink, getTestItemLink } from '../../api/const';
import UserProfile from './UserProfile';
import UserTestsHistory from './UserTestsHistory';

import { firebaseConfig } from '../../const/firebase.config';
import { UserFilters } from '../../const/filters';
import { filterData } from '../../functions/filter';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: spacing(2),
  },
  table: {
    overflow: 'auto',
    width: '100%',
  },
}));

const User = () => {
  const classes = useStyles();
  const user = useContext(UserContext);
  const location = useLocation();
  let passedFilter = null;
  if (location.state) {
    passedFilter = (location.state as { navigationFilter }).navigationFilter;
  }

  const [tableData, setTableData] = useState<ITest[]>([]);
  const [filteredTableData, setFilteredTableData] = useState<ITest[]>([]);
  const [filters, setFilters] = useState<any>(UserFilters);
  const [selectedTestId, setSelectedTestId] = useState<string | null>(null);
  const [userProfileVisible, setUserProfileVisible] =
    useState<boolean>(false);
  const [userTestsHistoryVisible, setUserTestsHistoryVisible] =
    useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(
    null
  );

  const onRowClick = (row: ITableRow) =>
    isRowSelected(row) ? setSelectedTestId(null) : setSelectedTestId(row.id);
  const isRowSelected = (row: ITableRow) => row.id === selectedTestId;

  const EditUserAction = (row: ITableRow) => {
    setSelectedUserId(row.id);
    setUserProfileVisible(true);
  };

  const setData = useCallback((arr: any) => {
    const filteredArr = [];
    arr.forEach((item: ITest) => {
      if (filteredArr.some((el) => el.patient.email === item.patient.email)) {
      } else {
        filteredArr.push(item);
      }
    });
    setTableData(filteredArr);
    setFilteredTableData(filteredArr);
  }, []);

  const handleFilterFieldValueChange = (val: Object) => {
    setFilters({ ...filters, ...val });
  };

  const clearSelectedFilters = () => {
    setFilters(UserFilters);
    setFilteredTableData(tableData);
  };

  useEffect(() => {
    passedFilter && setFilters({ ...filters, ...passedFilter });
  }, [location.state]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFilteredTableData(filterData(tableData, filters));
  }, [filters, tableData]);

  return (
    <Box className={classes.root}>
      <Card className={classes.table}>
        {tableData.length > 0 && (
          <TestsHeader
            tests={filteredTableData}
            getTestExportRow={getTestExportRow}
            exportTestsFields={exportTestsFields}
            linkForPdfReport={`https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net/generateTestReport?corporate_id=${user.corporation}`}
            areFiltersOnThePage
            filtersData={UserFilters}
            filters={filters}
            handleFilterFieldValueChange={handleFilterFieldValueChange}
            clearSelectedFilters={clearSelectedFilters}
          />
        )}

        <Table
          columns={testsColumns}
          link={getTestsListLink(user.corporation)}
          onRowClick={onRowClick}
          isRowSelected={isRowSelected}
          hasEditRow
          EditAction={(row) => EditUserAction(row)}
          tableData={filteredTableData}
          setTableData={setData}
        />
      </Card>
      {selectedTestId && (
        <TestDetails link={getTestItemLink(user.corporation, selectedTestId)} onClose={() => setSelectedTestId(null)} />
      )}
      {userTestsHistoryVisible && (
        <UserTestsHistory
          isOpen={userTestsHistoryVisible}
          onClose={() => setUserTestsHistoryVisible(false)}
          link={getTestsListLink(user.corporation)}
          selectedUserId={selectedUserId}
        />
      )}
      {userProfileVisible && selectedUserId && (
        <UserProfile
          isOpen={userProfileVisible && !!selectedUserId}
          onClose={() => setUserProfileVisible(false)}
          link={getTestItemLink(user.corporation, selectedUserId)}
          setUserProfileVisible={() => setUserTestsHistoryVisible(true)}
        />
      )}
    </Box>
  );
};

export default User;
