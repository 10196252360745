import { Box, Typography, Button, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import reorderBackground from '../../../../icons/reorderBackground.png';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: spacing(1),
    flexGrow: 1,
  },
  image: {
    width: 446,
    height: 300,
    marginBottom: spacing(9),
  },
  button: {
    padding: `${spacing(1.5)}px ${spacing(10)}px`,
    marginTop: spacing(2),
  },
}));

const Reorder = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <img src={reorderBackground} alt="img" className={classes.image} />

      <Typography variant="h5" color="textPrimary">
        Reorder Test Kits
      </Typography>

      <Typography color="textPrimary">
        Please contact us at{' '}
        <Typography component="span">
          <Link
            href="mailto:sales@camtech.org"
            target="_blank"
            rel="nofollow noopener"
          >
            sales@camtech.org
          </Link>
        </Typography>{' '}
        to order more COVID-19 testing kits.
      </Typography>

      <Typography color="textPrimary">
        Visit{' '}
        <Typography component="span">
          <Link
            href="https://www.covid19-test.com"
            target="_blank"
            rel="nofollow noopener"
          >
            www.covid19-test.com
          </Link>
        </Typography>{' '}
        to review all our product offers.
      </Typography>
      <Button
        color="primary"
        variant="contained"
        className={classes.button}
        href="mailto:info@camtech.org"
        target="_blank"
        rel="nofollow noopener"
      >
        Contact Us
      </Button>
    </Box>
  );
};

export default Reorder;
