import MenuItem from './MenuItem';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { menuItems } from '../../const/menu';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../App';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    width: 125,
    minWidth: 125,
  },
  button: {
    margin: spacing(2),
    backgroundColor: '#BFDDEB',
    color: '#3D75A3',
    padding: '5px 25px',
    '&:hover': {
      color: '#fff',
    },
  },
  link: {
    textDecoration: 'none',
  },
}));

const Menu = () => {
  const classes = useStyles();
  let location = useLocation();
  const [currentPath, setCurrentPath] = useState('/');

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  const [menus, setMenus] = useState(menuItems);
  const user = useContext(UserContext);

  useEffect(() => {
    if (user.corporation === 'ALL') {
      setMenus(menuItems.filter(m => ! m.nonAdminOnly));
    }
    else {
      setMenus(menuItems.filter(m => ! m.adminOnly));
    }
  }, [user]);

  return (
    <Box className={classes.root}>
      <Box>
        {menus.map((menuItem) => (
          <MenuItem
            key={menuItem.link}
            menuItem={menuItem}
            selected={menuItem.link === currentPath}
          />
        ))}
      </Box>
      <Link to="/more" className={classes.link}>
        <Box>
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
          >
            More
          </Button>
        </Box>
      </Link>
    </Box>
  );
};

export default Menu;
